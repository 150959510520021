import {Injectable} from '@angular/core';
import {EmptyShoppingCartErrors} from '../exception/empty-shopping-cart-errors';
import {ShoppingCartContainer} from '../../../shopping-cart/type/shopping-cart-container';
import {ShoppingCartItemDetails} from '../../../shopping-cart/type/shopping-cart-item-details';
import {XstitchApiGatewayService} from '../../api-client/xstitch-api-gateway/xstitch-api-gateway.service';
import {environment} from '../../../../environments/environment';
import {OrderCreationDetails} from './interfaces/order-creation-details';
import {PaypalMerchantDetails} from './interfaces/paypal-merchant-details';
import {StandardApiResponseDeprecated} from '../../api-client/xstitch-api-gateway/type/standard-api-response-deprecated';
import {OrderCaptureDetails} from './interfaces/order-capture-details';

@Injectable({
   providedIn: 'root'
})
export class PaypalService {

   constructor(private xstitchApiGateway: XstitchApiGatewayService) {
   }

   public getPaypalMerchantDetails(): Promise<PaypalMerchantDetails> {
      return new Promise<PaypalMerchantDetails>((resolve, reject) => {
         const endpoint = environment.xstitchApiEndpoints.checkout.paypal.getMerchantPublicDetails;

         const subscription = this.xstitchApiGateway.getAsJsonRelativeRoute<PaypalMerchantDetails>(endpoint);

         subscription.subscribe((observer: PaypalMerchantDetails) => {
            resolve(observer);
         }, error => {
            reject(StandardApiResponseDeprecated.constructFromHttpErrorResponse(error));
         });
      });
   }

   public captureOrder(orderId: string): Promise<OrderCaptureDetails> {
      const payload: Map<string, any> = new Map<string, string>();
      payload.set('paypalOrderId', orderId);

      const endpoint = environment.xstitchApiEndpoints.checkout.paypal.captureOrder;

      return new Promise<OrderCaptureDetails>((resolve, reject) => {
         this
            .xstitchApiGateway
            .postWithJsonBodyRelativeRoute<OrderCaptureDetails>(endpoint, payload)
            .subscribe((o: OrderCaptureDetails) => {
               resolve(o);
            }, (error) => {
               reject(error);
            });
      });
   }

   public createCheckoutOrder(
      shoppingCart: ShoppingCartContainer | null,
      returnFullUrl: string,
      cancelFullUrl: string
   ): Promise<OrderCreationDetails> {

      if (shoppingCart === null) {
         throw new EmptyShoppingCartErrors();
      }
      if (shoppingCart.countItems() === 0) {
         throw new EmptyShoppingCartErrors();
      }

      const shoppingItems: Array<any> = [];

      let thisPayload: any;
      let shoppingCartElement: ShoppingCartItemDetails;

      for (shoppingCartElement of shoppingCart) {
         thisPayload = {
            templateId: shoppingCartElement.getTemplateId(),
            quantity: 1,
            comments: shoppingCartElement.getUserNotes()
         };
         shoppingItems.push(thisPayload);
      }

      const payload: Map<string, any> = new Map<string, any>();

      payload.set('checkoutItems', shoppingItems);
      payload.set('returnFullUrl', returnFullUrl);
      payload.set('cancelFullUrl', cancelFullUrl);

      const endpoint = environment.xstitchApiEndpoints.checkout.paypal.createOrder;

      const obs = this.xstitchApiGateway.postWithJsonBodyRelativeRoute<OrderCreationDetails>(endpoint, payload);

      return new Promise<OrderCreationDetails>((resolve, reject) => {
         obs.subscribe((a: OrderCreationDetails) => {
            resolve(a);
         }, (e) => {
            reject(e);
         });
      });
   }
}
