import {Component, OnInit} from '@angular/core';
import {TranslationSheet} from '../../service/locale/static-dictionary/type/translation-sheet';
import {StaticDictionaryService} from '../../service/locale/static-dictionary/static-dictionary.service';
import {SetTitleService} from '../../service/seo/set-title/set-title.service';
import {SetCanonicalLinkService} from '../../service/seo/set-canonical/set-canonical-link.service';

@Component({
   selector: 'app-cookie-policy',
   templateUrl: './cookie-policy.component.html',
})
export class CookiePolicyComponent implements OnInit {

   translationSheet: TranslationSheet;

   constructor(private title: SetTitleService, staticDictionary: StaticDictionaryService, private canonical: SetCanonicalLinkService) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngOnInit(): void {
      this.title.setTitle(this.translationSheet.cookiePolicy);
      this.canonical.setCanonicalUrlBasedOnCurrentRoute();
   }
}
