import {Component, OnInit} from '@angular/core';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';
import {environment} from '../../environments/environment';
import {SetTitleService} from '../service/seo/set-title/set-title.service';
import {SetCanonicalLinkService} from '../service/seo/set-canonical/set-canonical-link.service';

@Component({
   selector: 'app-frequently-asked-questions',
   templateUrl: './frequently-asked-questions.component.html',
   styleUrls: ['./frequently-asked-questions.component.scss']
})
export class FrequentlyAskedQuestionsComponent implements OnInit {

   translationSheet: TranslationSheet;
   branding = environment.branding;
   contactUsUrl = environment.frontEndRoutes.contactUs;
   registerUrl = environment.frontEndRoutes.registerRoute;
   logInRoute = environment.frontEndRoutes.loginRoute;

   constructor(
      staticDictionary: StaticDictionaryService,
      private title: SetTitleService,
      private canonical: SetCanonicalLinkService
   ) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   ngOnInit(): void {
      this.title.setTitle(this.translationSheet.faq.frequentlyAskedQuestions);
      this.canonical.setCanonicalUrlBasedOnCurrentRoute();
   }
}
