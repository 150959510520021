import {AbstractDictionary} from './abstract-dictionary';
import {ParametrizedTranslation} from './parametrized-translation';

export class FrenchDictionary extends AbstractDictionary {

   constructor() {
      super();

      this.translationSheet = {
         yes: 'Oui',
         no: 'Non',
         errorReceivingMsg: 'Une erreur s\'est produite lors de la réception de votre message.',
         tryAgainContactUs: 'Veuillez réessayer. Si l\'erreur persiste, veuillez nous envoyer un e-mail à la place, à',
         whoopsDots: 'Oups...',
         messageReceived: 'Nous avons bien reçu votre message.',
         thanks: 'Merci',
         weWillContactYou: 'Un membre de notre personnel vous contactera sous peu.',
         send: 'Envoyer',
         pleaseWaitDots: 'S\'il vous plaît, attendez...',
         signUpFree: 'Inscription gratuite!',
         noAccountQuestionMark: 'Pas de compte?',
         privacyAndCookies: 'Avis de Confidentialité et de Cookies',
         signingNotice: 'En vous connectant, vous acceptez les conditions d\'utilisation et de vente de XStitch Library. Veuillez consulter notre',
         cancel: 'Annuler',
         forgotQuestionMark: 'Oubliée?',
         unexpectedLogin: 'Une erreur inattendue s\'est produite. Si l\'erreur persiste, veuillez',
         contactUsLowercase: 'nous contacter',
         subscribe: 'S\'abonner',
         leaveComment: 'Laissez-nous un commentaire',
         contactUs: 'Contactez-nous',
         byEmail: 'Par email',
         termsOfUse: 'Conditions d\'utilisation',
         cookiePolicy: 'Politique relative aux cookies',
         privacyNote: 'Avis de confidentialité',
         followUs: 'Suivez-nous',
         logIn: 'Se connecter',
         verify: 'Vous devez vérifier votre adresse e-mail avant de pouvoir vous connecter. Veuillez vérifier votre dossier spam pour l\'e-mail',
         resend: 'Renvoyez-moi l\'e-mail de vérification',
         noMatch: 'Ces informations de connexion ne correspondent pas. Veuillez revérifier votre adresse e-mail et votre mot de passe',
         close: 'Fermer',
         goToMyCollection: 'Aller à ma collection',
         homepage: 'Page d\'accueil',
         searchResults: 'Résultats de la recherche',
         page: 'page',
         group: 'grappe',
         faq: {
            frequentlyAskedQuestions: 'Foire aux questions',
            wrongOrder: {
               q: 'J\'ai passé une commande par erreur. Puis-je obtenir un remboursement?',
               a: 'Pour les utilisateurs inscrits, notre politique est d\'échanger le produit que vous avez acheté contre un autre de même prix, ou contre un autre de prix supérieur avec une remise. Aucun frais n\'est applicable.',
               a1: 'Pour être éligible à un échange, vous devez:',
               a2: new ParametrizedTranslation('Être un <a href="%registerUrl%" target="new">utilisateur inscrit</a>.'),
               a3: new ParametrizedTranslation('Vous étiez <a href="%logInUrl%" target="blank">connecté</a> au moment du paiement, nous pouvons donc retracer la transaction jusqu\'à son propriétaire légitime.'),
               a4: 'Le produit que vous avez acheté n\'a pas encore été téléchargé.',
               a5: 'Les remises, remboursements ou échanges ne sont pas applicables aux paiements anonymes, ni aux utilisateurs enregistrés qui ont effectué le paiement de manière anonyme.',
               a6: new ParametrizedTranslation('si vous avez un problème avec votre commande, veuillez <a href="%contactUsLink%" target="_blank">nous contacter</a> pour mieux vous aider')
            },
            resellPatterns: {
               q: 'Puis-je revendre vos patrons?',
               a: new ParametrizedTranslation('Tous les fichiers de modèles numériques de ce site Web sont la propriété de %branding% et sont destinés à un usage personnel uniquement. Tous les PDF générés sur ce site Web sont étiquetés de manière unique et identifiés par un QR. Nous n\'autorisons pas la vente des PDF à un tiers.'),
            },
            sellProducts: {
               q: 'Puis-je vendre les produits que j\'ai fabriqués avec les modèles que je vous ai achetés ?',
               a: 'Oui, nous n’avons pas notre mot à dire sur la meilleure utilisation de votre travail et de vos efforts, et nous sommes heureux de vous avoir aidé.'
            },
            downloads: {
               q: 'Comment puis-je télécharger le modèle que j\'ai acheté?',
               a: 'Paiements anonymes : vous recevrez un e-mail avec le lien de téléchargement. Vous pouvez accéder à ce lien sans avoir à vous inscrire ou à vous connecter. Veuillez conserver cet e-mail si vous devez télécharger à nouveau le modèle à l\'avenir. Le système ne conservera pas la trace des paiements anonymes et les e-mails ne seront pas renvoyés.',
               a1: 'Utilisateurs enregistrés : Vous recevrez un e-mail avec le lien de téléchargement. Vous pouvez accéder à ce lien sans avoir à vous connecter. Si vous êtes déjà connecté, vous serez redirigé vers votre tableau de bord personnel et vous aurez accès à tous vos modèles achetés. Si vous perdez l\'e-mail avec le lien de téléchargement, vous pouvez toujours télécharger les modèles depuis le tableau de bord de votre compte.',
               a2: new ParametrizedTranslation('Téléchargements gratuits : La %branding% est fière de proposer des modèles gratuitement. Si vous êtes intéressé par l\'un des nombreux modèles gratuits disponibles, vous devez être connecté lorsque vous ajoutez le modèle à votre collection. Vous recevrez également un e-mail avec le lien de téléchargement, mais vous devez être connecté pour télécharger le modèle.')
            },
            unlimitedFree: {
               q: 'Existe-t-il une limitation pour télécharger des modèles gratuits?',
               a: new ParametrizedTranslation('Non, il n\'y en a pas. Vous pouvez télécharger autant de modèles gratuits que vous le souhaitez. Cependant, en raison du grand nombre de demandes que le site reçoit, les clients payants seront prioritaires. La seule exigence est de <a href="%registerUrl%" target="new">s\'inscrire en tant qu\'utilisateur</a>, ce qui est (et sera toujours) gratuit.')
            },
            pdfAvailability: {
               q: 'Puis-je accéder à mes fichiers PDF à tout moment?',
               a: 'Oui, vous pouvez. Il n\'y a pas de limite de temps, vos PDF vous appartiennent pour toujours et peuvent être téléchargés à tout moment pendant toute la durée de vie du site.',
               a1: 'Il y a cependant quelques précisions à apporter. Afin de faciliter certaines tâches de maintenance dans nos systèmes, les fichiers PDF d\'un certain âge (plus de 30 jours) sont effectivement supprimés. Cependant, ils peuvent être régénérés à nouveau, sur demande et gratuitement. Dans ce cas, le système vous indiquera clairement comment les régénérer avant de les rendre à nouveau téléchargeables.'
            }
         },
         shoppingCart: {
            shoppingCartEmpty: 'Votre panier est vide',
            letStart: 'Commençons à magasiner',
            totalCheckout: 'Paiement total',
            confirm: 'Veuillez confirmer',
            remove: 'Supprimer',
            fromShoopingCartQuestionMark: 'du panier?',
            yesRemove: 'Oui, supprimer',
            initiateSession: 'Lancer la session',
            checkoutAsguest: 'Compléter en tant qu\'invité',
            completeCheckout: 'Terminer l\'achat',
            yourShoppingCart: 'Votre Panier',
            youAreNotLoggedIn: 'Vous n\'êtes pas connecté, mais vous pouvez continuer en tant qu\'invité.',
            youCanContinueAsGuest: 'Nous n\'avons besoin que d\'une adresse e-mail où envoyer les PDF générés et de votre nom pour vous adresser correctement.',
            sendTo: 'Envoyer à',
            optionally: {
               optionallyYouCan: 'En option, vous pouvez',
               login: 'vous connecter',
               or: 'ou',
               register: 'vous inscrire',
               soLinked: ', de sorte que l\'achat sera lié à vous.'
            },
            errors: {
               paypal: {
                  genericError: 'Oups... Une erreur inattendue s\'est produite lors de l\'utilisation de la passerelle PayPal et le paiement n\'a pas pu être finalisée. L\'administrateur système a été automatiquement informé et résoudra le problème sous peu. Veuillez réessayer dans quelques minutes.',
                  paymentDeclined: 'Oups... PayPal a refusé ce mode de paiement. Veuillez réessayer en en choisissant un autre.'
               }
            },
            progress: {
               checkingLogInStatus: 'Accepter l\'état de début de session',
               checkingShoppingCartContent: 'Contenu du panier',
               loadingPaypalPlugin: 'Chargement du PayPal',
               inProgress: 'Traitement du paiement'
            },
            success: {
               addedFreeTemplateSuccessTitle: 'Ajoutée',
               templateInYourCollection: 'Le modèle a été ajouté avec succès à votre collection.',
               successTitle: 'Succès',
               checkoutSuccessfullyCompleted: 'Le processus de paiement a été terminé avec succès.',
               yourPaymentReferenceIs: 'Votre référence de paiement est',
               pdfsGenerating: new ParametrizedTranslation('Les fichiers PDF sont maintenant en cours de génération et vous recevrez bientôt un e-mail au %emailAddress% avec les liens de téléchargement une fois terminés.'),
               checkSpamFolder: 'Veuillez vérifier votre dossier spam si vous n\'avez pas reçu dans les 6 heures.',
               thanks: 'Merci encore une fois de nous faire confiance.',
               variableGenerationTime: 'Le temps de génération du PDF varie, mais il doit être fait avant 24 h.'
            },
            anonymousCheckout: new ParametrizedTranslation('Vous êtes sur le point d\'effectuer un paiement anonyme. Veuillez consulter la section <a href="%faqUrl%" target="new">FAQ</a> pour savoir ce que cela signifie pour vous.')
         },

         advancedSearch: {
            title: 'Recherche avancée',
            keywords: 'Mots clés',
            separate: 'Séparez les mots clés à l\'aide de virgules',
            labels: 'Étiquettes',
            search: 'Recherche',
            example: 'par ex. monet, la promenade',
            reset: 'Réinitialiser',
            asTree: 'Arbre',
            asList: 'Liste'
         },

         topBarNavigation: {
            hello: 'Salut',
            signIn: 'Compte',
            languageSettings: 'Paramètres de langue',
            change: 'Changer',
            searchIn: 'Rechercher dans',
            categories: {
               all: 'Toute'
            }
         },

         favourites: {
            favouritesUpdatedTitle: 'Favoris mis à jour',
            localFavouritesSynced: 'Vos motifs favoris ont été transférés sur votre compte',
            closeModal: 'Fermer',
            logIn: 'Connectez-vous',
            favouritesUpdatedLocally: 'Votre ensemble de motifs favoris a été mis à jour dans une liste temporaire sur cet appareil.',
            toTransfer: 'pour transférer les modifications vers votre compte utilisateur.'
         },

         searchResultsPage: {
            whoopsNoResults: 'Oups, aucun résultat',
            explainNoResults: 'Les critères de recherche n\'ont donné aucun résultat.',
            tryAdvanced: 'Essayez la recherche avancée',
            notWhatYouLookingForQuestionMark: 'Pas ce que vous cherchez?',
            contactUs: 'Contactez-nous',
            describingYourIdea: 'en décrivant votre idée.',
            scrollDownForMore: 'Défiler vers le bas pour charger plus (page',
            shareSearch: 'Partagez cette collection',
            shareSearchDescription: 'Partagez les résultats de cette recherche en utilisant l\'une des méthodes suivantes :'
         },

         emailAddressFormComponent: {
            emailAddress: 'e-Mail',
            emailCompulsory: 'Le mail est obligatoire',
            invalidFormat: 'Format d\'e-mail non valide'
         },
         agreeTermsAndConditionsComponent: {
            iAgree: 'J\'accepte les termes et conditions et l\'',
            youMustAgree: 'Vous devez accepter avant de soumettre'
         },
         newsletterFormComponent: {
            newsletter: 'Bulletin',
            choose: 'Choisir',
            never: 'Jamais',
            weekly: 'Hebdomadaire',
            monthly: 'Mensuelle',
            eachNew: 'Chaque nouvelle parution',
            explanation: 'Veuillez sélectionner la fréquence à laquelle vous souhaitez recevoir notre newsletter'
         },
         passwordFormComponent: {
            password: 'mot de passe',
            repeatPasswordPlaceholder: 'Répéter le mot de passe',
            passwordIsCompulsory: 'Le mot de passe est obligatoire',
            passwordTooShort: 'Le mot de passe est trop court',
            passwordInvalid: 'Le mot de passe est invalide',
            repeatSamepassword: 'Veuillez répéter le même mot de passe',
            incorrectRepeatedPassword: 'Pour des raisons de sécurité, veuillez retaper le nouveau mot de passe'
         },
         problemInWebsite: 'Il semble y avoir un problème avec le site.',
         registerComponent: {
            newAccount: 'Nouveau compte',
            doYouHaveQuestionMark: 'Avez-vous déjà un compte?',
            tryAgain: 'Veuillez réessayer. Si l\'erreur persiste, veuillez',
            contactUs2: 'nous contacter',
            tryAgainCta: 'Réessayer',
            congratulations: 'Super!',
            successfullyRegistered: 'Vous avez configuré votre compte avec succès',
            emailSent: 'Nous vous avons envoyé un e-mail de confirmation sur votre compte de messagerie.',
            followInstructions: 'Veuillez suivre les instructions qui y sont décrites avant de vous connecter.',
            understand: 'Comprendre',
            registerCTA: 'S\'inscrire',
            sameEmailExists: new ParametrizedTranslation('Cet adresse e-mail est déjà enregistrée. Si c\'est vous, essayez de <a href="%logInRoute%">vous connecter</a> à la place'),
         },
         emailBodyComponent: {message: 'Message'},
         emailSubjectComponent: {
            subject: 'Matière',
            indicateAReason: 'Veuillez indiquer la raison',
            provideEmailSubject: 'Merci d\'indiquer le sujet',
            subjectTooLong: 'Sujet trop long',
            subjectTooShort: 'Sujet trop court'
         },
         usernameTextboxComponent: {
            yourName: 'votre nom',
            theUsernameCompulsory: 'Le nom d\'utilisateur est obligatoire',
            tooShort: 'Le nom d\'utilisateur est trop court',
            tooLong: 'Le nom d\'utilisateur est trop long',
            usernameInvalid: 'Le nom d\'utilisateur est invalide'
         },
         contactUsModule: {
            backHome: 'Retour au début',
            retry: 'Recommencez',
            otherWays: 'Autres moyens de contact',
            sendUsEmail: 'Envoyez-nous un email à'
         },

         genericErrorModal: {
            unexpectedErrorHappen: 'Une erreur inattendue s\'est produite et nous n\'avons pas pu traiter cette demande.',
            sysAdminsNotified: 'Nos administrateurs système ont déjà été informés et ils examineront ce problème dès que possible.',
            pleaseTryAgain: 'Veuillez réessayer et si l\'erreur persiste, veuillez',
            contactUs: 'nous contacter directement.'
         },

         patternActions: {
            shoppingCart: {
               addToShoppingCart: 'Ajouter au panier',
               removeFromShoppingCart: 'Retirer du panier'
            },
            sharePinterest: {
               share: 'Partager sur Pinterest',
               shared: 'Partagé sur Pinterest'
            },
            withAFriend: {
               share: 'Partager avec un ami',
               shared: 'Partagé avec un ami'
            },
            shareInFacebook: {
               share: 'Partager sur Facebook',
               shared: 'Partagé sur Facebook'
            },
            completeCheckout: 'Aller au paiement',
            favourites: {
               add: 'Ajouter aux favoris',
               remove: 'Retirer des favoris'
            }
         },

         patternDetail: {
            inYourCollection: 'Ce modèle vous appartient déjà et peut être téléchargé depuis votre collection privée.',
            generalDescription: 'Modèles de points de croix comptés avancés au format PDF. Artisanat de couture et de broderie pour mobile, tablette et ordinateur portable',
            pleaseRead: 'Veuillez lire attentivement toute la page avant d\'acheter!',
            notAKit: 'Ce n\'est PAS un kit. Fil et tissu NON inclus. Vous n\'achetez qu\'un fichier PDF exclusif de tableau de point de croix compté numérique avancé, pouvant être affiché sur des appareils mobiles, des tablettes et des ordinateurs portables.',
            limitations: 'En raison des limites et des incohérences des différents moniteurs d\'affichage, les couleurs que vous voyez sur votre écran peuvent ne pas être une reproduction totalement précise du produit réel. Les images d\'écran sont fournies à titre indicatif uniquement et ne doivent pas être considérées comme absolument correctes.',
            printing: 'Bien que cela soit tout à fait possible si vous le souhaitez, ce tableau n\'est PAS destiné à être imprimé en raison de sa taille et de son format. Vous obtiendrez une bien meilleure expérience si vous l\'ouvrez à partir de votre appareil préféré. N\'oubliez pas de choisir le format de votre choix.',
            aboutThisPattern: 'À propos de ce modèle',
            variationsTitle: 'Variantes',
            variationsDescription: 'Ce modèle se décline en différentes variantes. Choisissez celui qui vous convient le mieux.',
            usesSolid: 'Des couleurs de fil solide.',
            howLongToComplete: 'Combien de temps faut-il pour terminer?',
            weCanCalculate: 'Nous pouvons calculer la date approximative à laquelle ce modèle serait terminé si vous commenciez aujourd\'hui. Utilisez',
            thisCalculator: 'cette calculatrice',
            toFindOut: 'pour savoir',
            colors: 'Couleurs',
            changesToThePatternTitle: 'Modifications du motif',
            changesToThePatternDescription: 'Vous pouvez demander que des modifications soient apportées au modèle présenté ici. Veuillez nous donner une description de ce dont vous avez besoin, et ils seront traités après l\'achat, sans frais supplémentaires.',
            changestoThePatternCaption: 'Description des modifications',
            relatedSearchTags: 'Balises de recherche associées',
            chooseFabric: 'Choisissez la couleur du tissu',
            itWillHelpYou: 'Cela vous aidera à visualiser à quoi ressemblerait le travail final, en fonction de la couleur du tissu que vous choisissez. N\'oubliez pas que nous ne fournissons pas de matériaux, uniquement des modèles numériques',
            thisTemplateIsFree: 'Ce modèle est',
            freeCapitalized: 'Gratuit',
            freeLowercased: 'gratuit',
            addToCollection: 'Ajouter à la collection',
            comments: 'Commentaires',
            wrote: 'écrit',
            anonymous: 'Anonyme',
            logInToComment: 'Connectez-vous pour commenter',
            leaveAComment: 'Laissez un commentaire',
            public: 'Public',
            showMyName: 'Afficher mon nom',
            sendComment: 'Envoyer',
            received: 'Commentaire reçu',
            willBeReviewed: 'Merci d\'avoir participé. Un membre de notre équipe l\'examinera peu de temps avant de le rendre public, si vous avez choisi de le faire.',
            loginToAddFreeTemplate: {
               please: 'Veouillez',
               logIn: 'vous connecter',
               toAddThisTemplateToCollection: 'pour télécharger les PDF de ce modèle'
            },
            signUpForFree: {
               signUpForFree: 'Inscrivez-vous gratuitement',
               toGetAccess: 'pour accéder à tous les modèles gratuits et recevoir des mises à jour régulières. Cela ne prend que quelques secondes.'
            }
         },
         maxLengthTextAreaComponent: {
            pleaseWrite: 'S\'il vous plaît écrivez un commentaire',
            tooShort: 'Le texte est trop court. Min autorisé:',
            tooLong: 'Le texte est trop long. Max autorisé:',
            chars: 'caractères'
         },
         common: {
            french: 'Français',
            spanish: 'Espagnol',
            english: 'Anglais',
            next: 'Suivant',
            previous: 'Précédent',
            vatIncludedShort: 'TVA inc.',
            stitchPluralLowerCase: 'p.d.s.', // points de suture
            exit: 'Sortir',
            back: 'Arrière',
            showMore: 'Plus',
            date: 'Date',
            receipt: 'Reçu',
            amount: 'Montant'
         },
         ellipsizedToggleComponent: {
            readMore: 'Lire la suite',
            readLess: 'Cacher'
         },
         deadlineCalculator: {
            deadline: 'Calculateur de délai',
            yourResultsTitle: 'Vos résultats',
            thisPatternHas: 'Ce modèle a',
            fullStitches: 'points complets',
            youCanDo: 'Vous pouvez faire',
            stitchesPerHour: 'points par heure, pendant',
            hoursADay: 'heures par jour, en travaillant',
            daysAWeek: 'jours par semaine',
            itWillBeFinished: 'Il sera terminé le',
            recalculate: 'Recalculer',
            whoops: 'Oups, une sorte d\'erreur s\'est produite',
            pleaseProvide: 'S\'il vous plaît fournir les informations suivantes',
            howManyStitches: 'Combien de mailles pouvez-vous faire en une heure?',
            warning01: 'Veuillez introduire un nombre positif supérieur à 0 (par exemple 123)',
            howManyHoursForm: 'Combien d\'heures par jour consacreriez-vous à ce travail',
            warning02: 'Veuillez introduire un positif entre 0 et 24 (par exemple 4.5)',
            howManyDaysAWeekForm: 'Combien de jours par semaine consacreriez-vous à ce travail',
            warning03: 'Veuillez introduire un chiffre entre 1 et 7 (par exemple 3)',
            calculate: 'Calculer'
         },
         dimensionsCalculator: {
            dimensionsTitle: 'Dimensions',
            thisPatternIs: 'Ce modèle est de',
            stitchesWidth: 'points de largeur',
            stitchesHeight: 'points de hauteur. Il couvre',
            ofTheFabric: 'du tissu, avec',
            actualStitches: 'points réels au total',
            use: 'Utilisez',
            thisCalculator: 'ce calculateur',
            toFindOut: 'pour connaître les dimensions réelles en fonction du nombre de tissus utilisé',
            theBigger: 'Plus le nombre de tissus est grand, plus le résultat final est petit, mais aussi plus les détails sont fins',
            inchesShort: 'PO.',
            showInCentimetres: 'Afficher en centimètres',
            showInInches: 'Afficher en pouces',
            inches: 'Pouces',
            centimetres: 'Centimètres',
            realDimensions: 'Dimensions réelles',
            thisModelMeasures: 'est de',
            widthXHeight: '(largeur x hateur) points. Choisissez le type de tissu ci-dessous pour connaître les dimensions finales réelles.',
            chooseYourPreferredUnits: 'Choisissez vos unités de longueur préférées.'
         },
         resendValidationEmail: {
            resendLegendButton: 'Renvoyer',
            resendValidationEmail: 'Renvoyer l\'e-mail de validation',
            pleaseGiveUs: 'Veuillez nous donner l\'adresse e-mail que vous avez utilisée pour enregistrer votre compte.',
            weVeSentAVerificationEmail: 'Nous avons envoyé un e-mail de vérification à l\'adresse fournie.',
            pleaseFollowTheInstructions: 'Veuillez suivre les instructions qui s\'y trouvent.',
            backToLogIn: {
               backTo: 'Retour',
               logIn: 'connexion',
            },
            anErrorHadHappened: 'Une erreur s\'est produite',
            pleaseTryAgain: {
               please: 'Veuillez',
               tryAgain: 'réessayer',
               orWait: 'ou patienter quelques minutes.'
            },
            ifErrorPersists: {
               ifError: 'Si l\'erreur persiste, veuillez',
               contactUs: 'nous contacter'
            }
         },
         shareWithAFriend: {
            placeholder: 'Le nom de ton ami',
            shareButtonLegend: 'Partager',
            pleaseIntroduceDetails: 'Veuillez introduire les détails de la personne avec qui vous souhaitez partager ce modèle',
            modalCaption: 'Partager ce modèle',
            shareSuccessCaption: 'Partagé',
            shareSuccessDescription: 'Merci d\'avoir partagé. Nous continuerons à travailler dur pour vous fournir des contenus intéressants.',
            closeSuccessModalButtonLegend: 'Fermer'
         },
         unsubscribeEmailShare: {
            unsubscribe: 'Se désabonner',
            willStopSending: 'Veuillez confirmer que vous souhaitez arrêter de recevoir les notifications suivantes:',
            thanksForNotifying: 'Merci de nous avoir informés',
            youWillNot: 'Vous ne recevrez pas les notifications suivantes:',
            ifYouWantTo: 'L\'e-mail que vous venez de recevoir contient un lien pour réactiver ces notifications. Veuillez le suivre et le changement sera annulé.',
            pleaseContactUs: 'veuillez nous contacter',
            backHome: 'Page d\'accueil',
            templateShare: 'Modèles de point de croix envoyés par vos amis.',
            collectionShare: 'Collections de point de croix envoyées par vos amis.',
            didYouChangeYourMind: 'Avez-vous changé d\'avis?'
         },

         resubscribeEmailShare: {
            reSubscribeTitle: 'Se réabonner',
            explanation: 'Après confirmation, le système transmettra les notifications liées à:',
            resubscribeButtonLegend: 'Se réabonner',
            systemsEnabled: 'Les notifications suivantes liées à votre adresse e-mail ont été réactivées:',
            welcomeBack: 'Re-bienvenue'
         },
         verifyAccount: {
            welcome: 'Salut!',
            logIn: 'vous connecter',
            youSuccessfully: 'Votre compte a été validé avec succès.',
            youCanNow: 'Vous pouvez maintenant',
            withTheDetails: 'avec les informations que vous avez fournies ou accéder à la',
            backHome: 'page d\'accueil',
            apologies: 'Désolé de ne pas pouvoir vérifier votre compte.',
            pleaseTryAgain: 'Veuillez réessayer en ouvrant l\'e-mail de vérification que nous vous avons envoyé.',
            ifTheError: 'Si l\'erreur persiste, veuillez nous contacter.',
            contactUs: 'nous contacter'
         },
         resetPassword: {
            reset: 'Réinitialiser',
            resetAccountPassword: 'Réinitialiser le mot de passe du compte.',
            pleaseProvide: 'Veuillez fournir l\'e-mail que vous avez utilisé pour enregistrer votre compte.',
            resetMyAccountPassword: 'Réinitialiser le mot de passe de mon compte.',
            pleaseTypeIn: 'Veuillez saisir le nouveau mot de passe',
            changePassword: 'Changer le mot de passe',
            checkYourInbox: 'Vérifiez votre boîte de réception',
            weVeSent: 'Nous avons envoyé un e-mail à votre adresse e-mail enregistrée.',
            pleaseFollowTheInstructions: 'Veuillez suivre les instructions qui s\'y trouvent pour poursuivre le processus de réinitialisation de votre mot de passe.',
            ifYouHaventReceiveIt: 'Si vous ne l\'avez pas reçu, veuillez vérifier votre dossier spam.',
            close: 'Fermer',
            somethingWentWrongDots: '... quelque chose s\'est mal passé.',
            pleaseTryAgain: 'Veuillez réessayer',
            ifTheErrorPersists: 'Si l\'erreur persiste, veuillez',
            tryAgain: 'Essayer à nouveau',
            sucessExclamationMark: 'Succès!!!',
            yourPasswordReset: 'Votre mot de passe a été réinitialisé avec succès',
            noLongerValid: 'L\'e-mail que vous avez utilisé n\'est plus valide et vous devez demander à nouveau une réinitialisation du mot de passe.',
            unknownError: 'Une erreur inconnue s\'est produite.',
            pleaseTryAgainOrContactUs: 'Veuillez réessayer. Si l\'erreur persiste, veuillez',
            thereWasAnError: 'Il y avait une erreur.',
            weCouldnt: 'Nous n\'avons pas pu réinitialiser votre mot de passe.'
         },

         userDashboard: {
            title: 'Votre compte',
            emptyState: {
               emptyCollection: 'Collection vide',
               letsStart: 'Ajouter des projets'
            },
            account: 'Compte',
            collection: 'Collection',
            originalListing: 'Annonce originale',
            admin: 'Admin',
            accountSettings: {
               description: 'Dans cette page, vous pouvez modifier les paramètres de votre compte',
               changeName: 'Changer de nom',
               changePassword: 'Changer le mot de passe',
               changeNewsletterFrequency: 'Changer le bulletin',
               changeEmail: 'Changer l\'e-mail',
               passwordChanged: 'Mis à jour',
               passwordResetMessage: 'Le mot de passe a été réinitialisé avec succès.',
               redirectionNotice: 'Votre session en cours a été terminée. Veuillez vous reconnecter avec votre nouveau mot de passe.'
            }
         },

         downloadDetails: {
            downloadTokenNotFound: 'Non trouvé',
            ready: 'Les fichiers PDF ont été générés. Choisissez l\'une des options suivantes pour télécharger',
            downloadInstructions: 'Modèle prêt',
            summarizedByGrid: 'Résumé par grille',
            summarizedByColor: 'Résumé par couleur',
            mobile: 'Mobile',
            tablet: 'Tablette',
            desktop: 'PC',
            download: 'Télécharger',
            regenerateStatus: {
               title: 'Régénération nécessaire',
               willBeAvailable: 'Les fichiers PDF seront disponibles après avoir cliqué sur le bouton Régénérer ci-dessous.',
               regenerateButtonLegend: 'Régénérer',
               tooltip: {
                  generatedInThePast: 'Les fichiers PDF de ce modèle ont été générés dans le passé, mais récemment supprimés du site pour faciliter les tâches de maintenance.',
                  noWorries: 'Pas de soucis! ils peuvent être régénérés à tout moment sans frais supplémentaires.',
                  clickInButton: 'Cliquez simplement sur le bouton Régénérer et ils seront à nouveau disponibles.'
               }
            },
            inProgressStatus: {
               inProgressTitle: 'En cours',
               generatingNow: 'Les fichiers PDF sont en cours de génération.',
               willReceive: 'Vous recevrez une notification par e-mail lorsque vous serez prêt.',
               eta: 'Prêt en',
               hourSingular: 'heure',
               hourPlural: 'heures',
               lessThan: 'moins que'
            }
         },
         verification: {
            yourPdfShouldBeIn: new ParametrizedTranslation('Votre fichier PDF devrait être en %language%.'),
            language: 'Langue',
            verifiedTitle: 'Vérifié avec succès',
            successfullyVerified: 'Le QR que vous avez scanné a été émis par nous. Votre PDF devrait contenir les informations suivantes',
            detailsTitle: 'Détails',
            dimensionDetails: new ParametrizedTranslation('Votre projet mesure %stitchesWidth% points de largeur X %stitchesHeight% points de hauteur. Il couvre %coveragePercentage%% du tissu, avec un total de %totalStitches% points.'),
            pdfFormat: 'Format du PDF',
            pdfFormatDetails: new ParametrizedTranslation('Votre fichier PDF a été optimisé pour %device%. %summaryType%.'),
            somethingNotRight: 'Quelque chose ne va pas?',
            quoteTheFollowing: 'et mentionner la référence suivante',
            somethingNotRightContactUs: {
               predefinedSubject: 'En relation avec la référence',
               predefinedBody: new ParametrizedTranslation('Chère %branding% équipe, il y a quelque chose qui ne va pas avec mon jeton de vérification')
            }
         },

         videoPromo: {
            noVideo: 'Votre navigateur ne prend pas en charge la balise vidéo.',
            videoAsset: new ParametrizedTranslation('assets/website_promo/%videoType%/french.mp4'),
            slide: {
               noSymbols: {
                  title: 'Grille minimaliste',
                  body: 'Un seul symbole pour désigner un point complet. Plus facile à suivre et moins fatigant pour les yeux'
               },
               unlimitedColors: {
                  title: 'Toutes les couleurs',
                  body: 'Toutes les couleurs de fils dans une palette peuvent être utilisées sans augmenter la complexité du motif'
               },
               combinedPalettes: {
                  title: 'Palettes combinées',
                  body: 'Nous pouvons étendre la gamme de couleurs disponibles en combinant palettes.'
               },
               pdfFormats: {
                  title: 'Plusieurs formats',
                  body: 'Trois formats PDF optimisés pour votre appareil préféré : ordinateur de bureau, tablette ou mobile'
               },
               gridFormats: {
                  title: 'Deux types de grille',
                  body: 'Choisissez entre résumé par couleur ou résumé par grille'
               },
               allAvailableCombinations: {
                  title: 'Toutes les combinaisons',
                  body: 'À chaque achat, vous aurez accès à six fichiers PDF'
               },
               chooseLanguages: {
                  title: 'Multilingue',
                  body: 'Les PDF et le site web sont disponibles dans certaines des langues les plus parlées dans le monde.'
               },
               realisticPreview: {
                  title: 'Aperçu réaliste',
                  body: 'Obtenez un aperçu très proche de l\'apparence du travail final'
               }
            }
         }
      };
   }
}
