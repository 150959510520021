import {CountedDimensions} from './counted-dimensions';
import {AbstractPalette} from './palette/abstract-palette';
import {CountedDimensionsInterface} from '../interface/counted-dimensions-interface';
import {ProjectTemplateInterface} from '../interface/project-template-interface';
import {AbstractLanguage} from '../../../locale/language-detector/type/abstract-language';
import {Spanish} from '../../../locale/language-detector/type/spanish';
import {French} from '../../../locale/language-detector/type/french';
import {PaletteFactory} from './palette/palette-factory';

export class ProjectTemplate {
   private projectId: number;
   private templateId: number;
   private imgUrls: string[] = [];
   private countedDimensions: CountedDimensions[] = [];
   private widthInStitches: number;
   private heightInStitches: number;
   private coveragePercentage: number;
   private totalStitches: number;
   private palettes: AbstractPalette[] = [];
   private colorCount: number;
   private priceGbpIncludingTax: number;
   private facebookShareSpanishUrl: string;
   private facebookShareEnglishUrl: string;
   private facebookShareFrenchUrl: string;
   private pinterestShareSpanishUrl: string;
   private pinterestShareEnglishUrl: string;
   private pinterestShareFrenchUrl: string;
   private pinterestGuiNonce: string;
   private pinterestSharethumbnail: string;
   private inUserCollection: boolean;
   private canonicalUrl: string;
   private alternateEnglish: string;
   private alternateSpanish: string;
   private alternateFrench: string;

   static constructFromInterface(si: ProjectTemplateInterface): ProjectTemplate {
      const result = new ProjectTemplate();

      result.templateId = si.templateId;
      si.imgUrls.forEach((imageUrl: string) => {
         result.imgUrls.push(imageUrl);
      });

      result.widthInStitches = si.stitchesWidth;
      result.heightInStitches = si.stitchesHeight;
      result.coveragePercentage = si.coveragePercentage;
      result.totalStitches = si.totalStitches;

      result.palettes = PaletteFactory.collectionFromInterfaces(si.palettes);

      si.countedDimensions.forEach((cdi: CountedDimensionsInterface) => {
         result.countedDimensions.push(CountedDimensions.constructFromInterface(cdi));
      });

      result.priceGbpIncludingTax = si.priceGbpIncludingTax;
      result.facebookShareEnglishUrl = si.socialMediaShare.facebook.shareEnglishUrl;
      result.facebookShareFrenchUrl = si.socialMediaShare.facebook.shareFrenchUrl;
      result.facebookShareSpanishUrl = si.socialMediaShare.facebook.shareSpanishUrl;

      result.pinterestShareEnglishUrl = si.socialMediaShare.pinterest.shareEnglishUrl;
      result.pinterestShareFrenchUrl = si.socialMediaShare.pinterest.shareFrenchUrl;
      result.pinterestShareSpanishUrl = si.socialMediaShare.pinterest.shareSpanishUrl;
      result.pinterestGuiNonce = si.socialMediaShare.pinterest.guiNonce;
      result.pinterestSharethumbnail = si.socialMediaShare.pinterest.thumbnail;
      result.projectId = si.projectId;

      result.inUserCollection = si.inUserCollection;

      result.canonicalUrl = si.canonicalUrls.canonical;
      result.alternateEnglish = si.canonicalUrls.alternateEnglish;
      result.alternateSpanish = si.canonicalUrls.alternateSpanish;
      result.alternateFrench = si.canonicalUrls.alternateFrench;

      return result;
   }

   getCanonicalUrl(): string {
      return this.canonicalUrl;
   }

   getCanonicalAlternateEnglish(): string {
      return this.alternateEnglish;
   }

   getCanonicalAlternateFrench(): string {
      return this.alternateFrench;
   }

   getCanonicalAlternateSpanish(): string {
      return this.alternateSpanish;
   }

   getTemplateId(): number {
      return this.templateId;
   }

   getImgUrls() {
      return this.imgUrls;
   }

   getFirstImageUrl(): string {
      let result: string | null;
      result = this.imgUrls[0];
      if (result === null) {
         throw new Error('First image not available. Empty collection?');
      }
      return result;
   }

   getPriceGbpIncludingTax(): number {
      return this.priceGbpIncludingTax;
   }

   getPalettes(): AbstractPalette[] {
      return this.palettes;
   }

   getPalettesBriefDescription(): string {
      let result = '';

      this.palettes.forEach((p: AbstractPalette) => {
         result = result + p.getUserFriendlyDescriptor() + ' (x ' + p.getColorCount() + ') + ';
      });

      result = result.substr(0, result.length - 3);
      return result;
   }

   getCountedDimensions(): CountedDimensions[] {
      return this.countedDimensions;
   }

   getColorCount(): number {
      return this.colorCount;
   }

   isInUserCollection(): boolean {
      return this.inUserCollection;
   }

   getWidthInStitches(): number {
      return this.widthInStitches;
   }

   getHeightInStitches(): number {
      return this.heightInStitches;
   }

   getCoveragePercentage(): number {
      return this.coveragePercentage;
   }

   getTotalStitches(): number {
      return this.totalStitches;
   }

   getFacebookShareSpanishUrl(): string {
      return this.facebookShareSpanishUrl;
   }

   getFacebookShareFrenchUrl(): string {
      return this.facebookShareFrenchUrl;
   }

   getFacebookShareEnglishUrl(): string {
      return this.facebookShareEnglishUrl;
   }

   getPinterestShareSpanishUrl(): string {
      return this.pinterestShareSpanishUrl;
   }

   getPinterestShareFrenchUrl(): string {
      return this.pinterestShareFrenchUrl;
   }

   getPinterestShareEnglishUrl(): string {
      return this.pinterestShareEnglishUrl;
   }

   getFacebookShareForLanguage(language: AbstractLanguage): string {
      if (language instanceof Spanish) {
         return this.facebookShareSpanishUrl;
      }

      if (language instanceof French) {
         return this.facebookShareFrenchUrl;
      }

      return this.facebookShareEnglishUrl;
   }

   getPinterestShareForLanguage(language: AbstractLanguage): string {
      if (language instanceof Spanish) {
         return this.pinterestShareSpanishUrl;
      }

      if (language instanceof French) {
         return this.pinterestShareFrenchUrl;
      }

      return this.pinterestShareEnglishUrl;
   }

   getPinterestGuiNonce(): string {
      return this.pinterestGuiNonce;
   }

   getPinterestShareThumbnail(): string {
      return this.pinterestSharethumbnail;
   }

   isFreeOfCharge(): boolean {
      return this.priceGbpIncludingTax === 0;
   }

   getProjectId(): number {
      return this.projectId;
   }
}
