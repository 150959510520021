import {Injectable} from '@angular/core';
import {UserService} from '../api-client/user/user.service';
import {XstitchApiGatewayService} from '../api-client/xstitch-api-gateway/xstitch-api-gateway.service';
import {Observable, Subject, Subscriber} from 'rxjs';
import {StandardApiResponseDeprecated} from '../api-client/xstitch-api-gateway/type/standard-api-response-deprecated';
import {PatternFavouritesContainer} from './type/pattern-favourites-container';
import {ProjectFavouritesEntry} from './type/project-favourites-entry';
import {EventLoggerService} from '../api-client/events/event-logger.service';
import {xstitchApiEndpoints, xstitchApiErrorCodes} from '../../../environments/common';
import {AbstractFavouritesStorageType} from './type/favourites-storage/abstract-favourites-storage-type';
import {RemoteFavouritesStorage} from './type/favourites-storage/remote-favourites-storage';
import {LocalFavouritesStorage} from './type/favourites-storage/local-favourites-storage';
import {AbstractFavouritesAction} from './type/favourites-action/abstract-favourites-action';
import {RemoveFromFavourites} from './type/favourites-action/remove-from-favourites';
import {AddToFavourites} from './type/favourites-action/add-to-favourites';
import {SyncFavourites} from './type/favourites-action/sync-favourites';
import {FavouritesItemInterface} from './interface/favourites-item-interface';

@Injectable({
   providedIn: 'root'
})
export class FavouritesManagerService {

   private static readonly PROJECT_FAVOURITE_LOCAL_STORAGE_ENTRY = 'rtbLreqy00';

   favouritesUpdatedSubject = new Subject<AbstractFavouritesAction>();

   private static readFromLocalStore(): PatternFavouritesContainer {
      const storage = localStorage.getItem(FavouritesManagerService.PROJECT_FAVOURITE_LOCAL_STORAGE_ENTRY);
      if ((storage === '') || (storage === null) || (storage === undefined)) {
         return new PatternFavouritesContainer();
      }

      return PatternFavouritesContainer.unserialize(storage);
   }

   private static writeToLocalStorage(c: PatternFavouritesContainer): void {
      const result = c.serializeAsObject();
      const s = JSON.stringify(result);
      localStorage.setItem(FavouritesManagerService.PROJECT_FAVOURITE_LOCAL_STORAGE_ENTRY, s);

   }


   constructor(
      private userService: UserService,
      private xstitchApiGateway: XstitchApiGatewayService,
      private eventLoggerService: EventLoggerService
   ) {

   }

   removeProjectFromFavourites(projectId: number): Observable<AbstractFavouritesStorageType> {
      return new Observable<AbstractFavouritesStorageType>((subscriber: Subscriber<AbstractFavouritesStorageType>) => {
         this.eventLoggerService.triggerRemoveFromFavouritesEvent(projectId).subscribe(() => {
            subscriber.next(new RemoteFavouritesStorage());
            this.favouritesUpdatedSubject.next(new RemoveFromFavourites(projectId));
         }, (errorReport: StandardApiResponseDeprecated) => {
            if (errorReport.containsErrorItemCode(xstitchApiErrorCodes.users.user_not_logged_in_deprecated)) {
               const favouritesList = FavouritesManagerService.readFromLocalStore();
               favouritesList.remove(new ProjectFavouritesEntry(projectId));
               FavouritesManagerService.writeToLocalStorage(favouritesList);
               subscriber.next(new LocalFavouritesStorage());
               this.favouritesUpdatedSubject.next(new RemoveFromFavourites(projectId));
            } else {
               subscriber.error(errorReport);
            }
         });
      });
   }

   addProjectToFavourites(projectId: number): Observable<AbstractFavouritesStorageType> {
      return new Observable<AbstractFavouritesStorageType>((subscriber: Subscriber<AbstractFavouritesStorageType>) => {

         this.eventLoggerService.triggerAddToFavouriteEvent(projectId).subscribe(() => {
            subscriber.next(new RemoteFavouritesStorage());
            this.favouritesUpdatedSubject.next(new AddToFavourites(projectId));
         }, (errorReport: StandardApiResponseDeprecated) => {
            if (errorReport.containsErrorItemCode(xstitchApiErrorCodes.users.user_not_logged_in_deprecated)) {
               const favouritesList = FavouritesManagerService.readFromLocalStore();
               favouritesList.add(new ProjectFavouritesEntry(projectId));
               FavouritesManagerService.writeToLocalStorage(favouritesList);
               subscriber.next(new LocalFavouritesStorage());
               this.favouritesUpdatedSubject.next(new AddToFavourites(projectId));
            } else {
               subscriber.error(errorReport);
            }
         });
      });
   }

   getCurrentUserFavouritesList(): Observable<PatternFavouritesContainer> {
      return new Observable<PatternFavouritesContainer>((subscriber: Subscriber<PatternFavouritesContainer>) => {

         const url = xstitchApiEndpoints.user.get_user_favourite_patterns_list;

         this
            .xstitchApiGateway
            .getAsJsonRelativeRoute<FavouritesItemInterface[]>(url)
            .subscribe((collection: FavouritesItemInterface[]) => {
               const favouritesList = new PatternFavouritesContainer();

               collection.forEach((a: FavouritesItemInterface) => {
                  favouritesList.add(new ProjectFavouritesEntry(a.id));
               });
               subscriber.next(favouritesList);
            }, () => {
               const local = FavouritesManagerService.readFromLocalStore();
               subscriber.next(local);
            });
      });
   }

   /**
    * Returns true if there were patterns in local storage that have been synced. False otherwise.
    */
   syncLocalStorageFavourites(): Observable<boolean> {
      return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
         const favouritesList = FavouritesManagerService.readFromLocalStore();
         if (favouritesList.count() !== 0) {

            const serializedContent = favouritesList.serializeAsObject();

            const payload: Map<string, object> = new Map<string, object>();
            payload.set('localFavourites', serializedContent);

            const endpoint = xstitchApiEndpoints.user.sync_local_favourite_projects;

            this.xstitchApiGateway.postWithJsonBodyRelativeRoute(endpoint, payload).subscribe(() => {
               favouritesList.clear();
               FavouritesManagerService.writeToLocalStorage(favouritesList);
               subscriber.next(true);
               this.favouritesUpdatedSubject.next(new SyncFavourites());
            }, () => {
               subscriber.next(false);
            });
         } else {
            this.favouritesUpdatedSubject.next(new SyncFavourites());
         }

         subscriber.next();

      });
   }
}
