import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LogInPageComponent} from './log-in-page/log-in-page.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {CookiePolicyComponent} from './legal/cookie-policy/cookie-policy.component';
import {HomePageComponent} from './home-page/home-page.component';
import {RegisterPageComponent} from './register-page/register-page.component';
import {SearchResultsComponent} from './search-results/search-results.component';
import {PatternDetailComponent} from './pattern-detail/pattern-detail.component';
import {ShoppingCartComponent} from './shopping-cart/shopping-cart.component';
import {UserAccountVerifyComponent} from './user-account-verify/user-account-verify.component';
import {UserHomePageComponent} from './user-home-page/user-home-page.component';
import {ResendValidationEmailComponent} from './resend-validation-email/resend-validation-email.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {UnsubscribeEmailShareComponent} from './email-share-subscription-manager/unsubscribe-email-share/unsubscribe-email-share.component';
import {AdvancedSearchComponent} from './advanced-search/advanced-search.component';
import {environment} from '../environments/environment';
import {AddFreePatternComponent} from './pattern-detail/add-free-pattern/add-free-pattern.component';
import {CommonDownloadAreaComponent} from './common-download-area/common-download-area.component';
import {PdfVerificationComponent} from './pdf-verification/pdf-verification.component';
import {RevisitSearchIdComponent} from './search-results/revisit-search-id/revisit-search-id.component';
import {ResubscribeEmailShareComponent} from './email-share-subscription-manager/resubscribe-email-share/resubscribe-email-share.component';
import {FrequentlyAskedQuestionsComponent} from './frequently-asked-questions/frequently-asked-questions.component';

const routes: Routes = [
   {path: environment.frontEndRoutes.shoppingCartRoute, component: ShoppingCartComponent},
   {path: environment.frontEndRoutes.loginRoute, component: LogInPageComponent},

   {
      path: 'account/forgot-account-password', children: [
         {path: ':forgot-password-token', component: ForgotPasswordComponent},
         {path: '', component: ForgotPasswordComponent}
      ]
   },

   {path: environment.frontEndRoutes.registerRoute, component: RegisterPageComponent},

   {path: 'legal/cookie-policy', component: CookiePolicyComponent},

   {path: environment.frontEndRoutes.frequentlyAskedQuestions, component: FrequentlyAskedQuestionsComponent},

   {
      path: environment.frontEndRoutes.searchResults, children: [
         {path: '', component: SearchResultsComponent},
         {path: environment.frontEndRoutes.revisitEmailSearchResults, component: RevisitSearchIdComponent},
         {path: '*', component: SearchResultsComponent},
      ]
   },

   {
      path: 'pattern/:pattern-id/template', children: [
         {path: ':template-id', component: PatternDetailComponent},
         {path: '', component: PatternDetailComponent},
         {path: '*', component: PatternDetailComponent},
      ]
   },

   {path: environment.frontEndRoutes.commonTemplateDownloadArea, component: CommonDownloadAreaComponent},

   {path: environment.frontEndRoutes.addFreeTemplateToCollection, component: AddFreePatternComponent},

   {path: environment.frontEndRoutes.pdfVerification, component: PdfVerificationComponent},

   {path: 'user/account/verify/:email-address/:account-verification-token', component: UserAccountVerifyComponent},
   {path: environment.frontEndRoutes.accountHome, component: UserHomePageComponent},
   {path: 'user/account/resend-verification-email', component: ResendValidationEmailComponent},
   {path: 'contact-us', component: ContactUsComponent},
   {path: environment.frontEndRoutes.unsubscribeSharing, component: UnsubscribeEmailShareComponent},
   {path: environment.frontEndRoutes.resubscribeSharing, component: ResubscribeEmailShareComponent},
   {path: 'search/advanced', component: AdvancedSearchComponent},
   {path: '', component: HomePageComponent},
   {path: '**', component: HomePageComponent}
];

@NgModule({
   imports: [RouterModule.forRoot(routes)],
   exports: [RouterModule]
})
export class AppRoutingModule {
}
