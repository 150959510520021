import {Injectable} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {environment} from '../../../environments/environment';
import {AbstractLanguage} from '../locale/language-detector/type/abstract-language';
import {AbstractSalesChannel} from '../../shopping-cart/type/sales-channels/abstract-sales-channel';
import {LanguageFactory} from '../locale/language-detector/helper/language-factory';
import {SalesChannelFactory} from '../../shopping-cart/helper/sales-channel-factory';
import {ExternalClickDetails} from './type/external-click-details';
import {LanguageDetectorService} from '../locale/language-detector/language-detector.service';
import {Website} from '../../shopping-cart/type/sales-channels/website';
import {UnknownSalesChannel} from '../../shopping-cart/type/sales-channels/unknown-sales-channel';

@Injectable({
   providedIn: 'root'
})
export class ExternalClickService {

   constructor(private activatedRoute: ActivatedRoute, private languageDetectorService: LanguageDetectorService) {
   }

   public externalClickEventDetected(): Observable<ExternalClickDetails> {
      return new Observable<ExternalClickDetails>((subscriber: Subscriber<ExternalClickDetails>) => {
         this.activatedRoute.queryParamMap.subscribe((q: ParamMap) => {

            if (q.has(environment.queryStringParameterNames.externalClick)) {
               subscriber.next(this.externalClickIdentified(q));
            } else {
               subscriber.next(new ExternalClickDetails(
                  this.languageDetectorService.getLanguage(),
                  new UnknownSalesChannel())
               );
            }
         });
      });
   }

   private externalClickIdentified(q: ParamMap): ExternalClickDetails {

      const languageLiteralQs = q.get(environment.queryStringParameterNames.languageLiteral);
      const salesChannelIdQs = q.get(environment.queryStringParameterNames.salesChannel);

      let language: AbstractLanguage = this.languageDetectorService.getLanguage();
      let salesChannel: AbstractSalesChannel = new Website();

      if (languageLiteralQs !== null) {
         try {
            language = LanguageFactory.createFromString(languageLiteralQs);
         } catch (e) {
            // Fail silently
         }
      }

      if (salesChannelIdQs !== null) {
         try {
            salesChannel = SalesChannelFactory.constructFromDbId(parseInt(salesChannelIdQs, 10));
         } catch (e) {
            // Fail silently
         }
      }

      return new ExternalClickDetails(language, salesChannel);
   }
}
