import {AbstractLanguage} from '../../locale/language-detector/type/abstract-language';
import {AbstractSalesChannel} from '../../../shopping-cart/type/sales-channels/abstract-sales-channel';

export class ExternalClickDetails {
   constructor(
      private language: AbstractLanguage,
      private salesChannel: AbstractSalesChannel
   ) {
   }

   public getLanguage(): AbstractLanguage {
      return this.language;
   }

   public getSalesChannel(): AbstractSalesChannel {
      return this.salesChannel;
   }
}
