export class PayloadNamingRepo {
   public static readonly SEARCH_ID = 'searchId';
   public static readonly TEMPLATE_ID = 'templateId';
   public static readonly FRIEND_EMAIL = 'friend_email';
   public static readonly USER_NAME = 'user_name';
   public static readonly TEMPLATE_ID_DEPRECATED = 'template_id';
   public static readonly MANAGING_TOKEN = 'managingToken';
   public static readonly PROJECT_ID = 'projectId';
   public static readonly FRIEND_NAME = 'friendName';
   public static readonly EMAIL = 'email';
   public static readonly PASSWORD = 'password';
   public static readonly NEWSLETTER_FREQUENCY_ID = 'newsletter_frequency_id';
   public static readonly CLEAR_TEXT_PASSWORD = 'clear_text_password';
   public static readonly ID = 'id';
   public static readonly DESCRIPTION_ENGLISH = 'descriptionEnglish';
   public static readonly DESCRIPTION_SPANISH = 'descriptionSpanish';
   public static readonly DESCRIPTION_FRENCH = 'descriptionFrench';
   public static readonly PROJECT_TAGS = 'tags';
   public static readonly PROJECT_TITLE_ENGLISH = 'projectTitleEnglish';
   public static readonly PROJECT_TITLE_SPANISH = 'projectTitleSpanish';
   public static readonly PROJECT_TITLE_FRENCH = 'projectTitleFrench';
   public static readonly TAG_CSS_SUBCLASS = 'cssSubclass';
   public static readonly TAG_SHOW_HOMEPAGE = 'showHomepage';
   public static readonly TAG_HOMEPAGE_IMG = 'tagImgHomepageLocation';
   public static readonly TAG_PARENT_ID = 'parentId';
   public static readonly TAG_FILTER_KEYWORD = 'filterKeyword';
   public static readonly TEMPLATE_LIVE = 'isLive';
   public static readonly TEMPLATE_GRID_MODULE = 'gridModuleId';
   public static readonly TEMPLATE_PRICE_GBP_INCLUDING_TAX = 'priceGbpIncludingTax';
   public static readonly TEMPLATE_PALETTES = 'palettes';
   public static readonly LANGUAGE_ID = 'languageId';
   public static readonly SALES_CHANNEL = 'salesChannel';
}
