import {Injectable} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {ProjectFullInfo} from '../project/type/project-full-info';
import {environment} from '../../../../environments/environment';
import {XstitchApiGatewayService} from '../xstitch-api-gateway/xstitch-api-gateway.service';
import {HttpResponse} from '@angular/common/http';
import {StandardApiResponseDeprecated} from '../xstitch-api-gateway/type/standard-api-response-deprecated';
import {PatternSearchResultsInterface} from './interface/pattern-search-results-interface';
import {PatternSearchResults} from './type/pattern-search-results';
import {SearchRequest} from './type/search-request';
import {TagDetailsObject} from '../home-page/object/tag-details.object';
import {TagDetailsInterface, TagWithChildrenInterface} from '../home-page/interface/tag-details.interface';
import {TagDetailsWithChildrenObject} from '../home-page/object/tag-details-with-children.object';
import {PublicProjectComment} from './type/public-project-comment';
import {PublicProjectCommentInterface} from './interface/public-project-comment-interface';
import {PayloadNamingRepo as PNR} from '../xstitch-api-gateway/payload-naming-repo';
import {SharingPlatforms} from './type/sharing-platforms';
import {ExternalClickDetails} from '../../external-click/type/external-click-details';

@Injectable({
   providedIn: 'root'
})
export class SearchPatternsService {

   constructor(private xstitchApiGateway: XstitchApiGatewayService) {
   }

   public getPatternById(patternId: number): Observable<ProjectFullInfo> {
      return new Observable<ProjectFullInfo>((subscriber: Subscriber<ProjectFullInfo>) => {
         const searchParameters = new SearchRequest([], [], []);
         searchParameters.includePatternId(patternId);
         this.searchAndGetTemplateFullInfo(searchParameters)
            .subscribe((results: PatternSearchResults) => {
               if (results.getPatternsFound().length === 1) {
                  subscriber.next(results.getPatternsFound().pop());
               }
            }, error => {
               subscriber.error(error);
            });
      });
   }

   public revisitSearchEmail(managingToken: string, platform: SharingPlatforms): Observable<PatternSearchResults> {

      let endpoint: string;

      if (platform === SharingPlatforms.email) {
         endpoint = environment.xstitchApiEndpoints.search.revisitSearchEmail;
      } else if (platform === SharingPlatforms.facebook) {
         endpoint = environment.xstitchApiEndpoints.search.revisitSearchSocialMedia;
      } else {
         throw new Error('Not implemented');
      }


      endpoint = endpoint.replace(environment.xstitchApiEndpointPathParameters.managingToken, managingToken);

      return new Observable<PatternSearchResults>((subscriber: Subscriber<PatternSearchResults>) => {

         const response = this
            .xstitchApiGateway
            .getAsJsonRelativeRoute<PatternSearchResultsInterface>(endpoint);

         response.subscribe((r: PatternSearchResultsInterface) => {
            subscriber.next(PatternSearchResults.constructFromInterface(r));
         }, error => {
            subscriber.error(StandardApiResponseDeprecated.constructFromHttpErrorResponse(error));
         });

      });
   }

   public searchAndGetTemplateFullInfo(
      searchParameters: SearchRequest
   ): Observable<PatternSearchResults> {
      const payload = searchParameters.serializeAsQueryString();

      const endpoint = environment.xstitchApiEndpoints.search.searchAndGetTemplateFullInfo + '?' + payload;

      return new Observable<PatternSearchResults>((subscriber: Subscriber<PatternSearchResults>) => {
         const response = this.xstitchApiGateway.getDeprecated(endpoint);

         response.subscribe((observer: HttpResponse<any>) => {

            const body = observer.body as PatternSearchResultsInterface;

            subscriber.next(PatternSearchResults.constructFromInterface(body));
         }, error => {
            subscriber.error(StandardApiResponseDeprecated.constructFromHttpErrorResponse(error));
         });
      });
   }


   public getFullTagTree(): Observable<TagDetailsWithChildrenObject[]> {
      const endpoint = environment.xstitchApiEndpoints.search.getTreeTagsReport;

      return new Observable<TagDetailsWithChildrenObject[]>((subscriber: Subscriber<TagDetailsWithChildrenObject[]>) => {
         const response = this.xstitchApiGateway.getDeprecated(endpoint);

         const collection: TagDetailsWithChildrenObject[] = [];

         response.subscribe((observer2: HttpResponse<any>) => {

            observer2.body.forEach((t: TagWithChildrenInterface) => {
               collection.push(TagDetailsWithChildrenObject.constructFromApi(t));
            });

            subscriber.next(collection);
         }, error => {
            subscriber.error(error);
         });
      });
   }

   public getFullTagList(): Observable<TagDetailsObject[]> {
      const endpoint = environment.xstitchApiEndpoints.search.getAllTagsReport;

      return new Observable<TagDetailsObject[]>(
         (subscriber: Subscriber<TagDetailsObject[]>) => {
            const response = this.xstitchApiGateway.getDeprecated(endpoint);

            const collection = new Array<TagDetailsObject>();
            response.subscribe((observer2: HttpResponse<any>) => {
               observer2.body.forEach((t: TagDetailsInterface) => {
                  collection.push(TagDetailsObject.constructFromApi(t));
               });
               subscriber.next(collection);
            }, error => {
               subscriber.error(error);
            });
         });
   }

   public logTemplateVisit(templateId: number, externalClickDetails: ExternalClickDetails): void {
      const endpoint = environment.xstitchApiEndpoints.events.templateVisit;
      const payload = new Map<string, any>();

      payload
         .set(PNR.TEMPLATE_ID, templateId)
         .set(PNR.LANGUAGE_ID, externalClickDetails.getLanguage().getDbId())
         .set(PNR.SALES_CHANNEL, externalClickDetails.getSalesChannel().getDbId())
      ;

      this.xstitchApiGateway.postWithJsonBodyRelativeRoute(endpoint, payload).subscribe();
   }

   public getProjectPublicComments(projectId: number): Observable<PublicProjectComment[]> {
      const endpoint = environment.xstitchApiEndpoints.search.getProjectPublicComments + '/' + projectId.toString();

      return new Observable<PublicProjectComment[]>((subscriber: Subscriber<PublicProjectComment[]>) => {

         const collection = new Array<PublicProjectComment>();

         const request = this.xstitchApiGateway.getAsJsonRelativeRoute<PublicProjectCommentInterface[]>(endpoint);

         request.subscribe((a: PublicProjectCommentInterface[]) => {
            a.forEach((i: PublicProjectCommentInterface) => {
               collection.push(PublicProjectComment.constructFromInterface(i));
            });

            subscriber.next(collection);
         }, (error) => {
            subscriber.error(error);
         });
      });
   }
}
