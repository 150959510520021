import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TopNavigationBarComponent} from './top-navigation-bar/top-navigation-bar.component';
import {CommonNavBarRightComponent} from './top-navigation-bar/common-nav-bar-right/common-nav-bar-right.component';
import {CommonSearchBarComponent} from './top-navigation-bar/common-search-bar/common-search-bar.component';
import {LogInPageComponent} from './log-in-page/log-in-page.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {CookiePolicyComponent} from './legal/cookie-policy/cookie-policy.component';
import {C01WhatThisPolicyCoversComponent} from './legal/cookie-policy/c01-what-this-policy-covers/c01-what-this-policy-covers.component';
import {C02PersonalDataWeCollectComponent} from './legal/cookie-policy/c02-personal-data-we-collect/c02-personal-data-we-collect.component';
import {C03WhyWeCollectTheDataComponent} from './legal/cookie-policy/c03-why-we-collect-the-data/c03-why-we-collect-the-data.component';
import {C04LegitimateUseComponent} from './legal/cookie-policy/c04-legitimate-use/c04-legitimate-use.component';
import {C05SharingPersonalDataWithProvidersComponent} from './legal/cookie-policy/c05-sharing-personal-data-with-providers/c05-sharing-personal-data-with-providers.component';
import {C06SharingPersonalDataWithOtherOrganisationsComponent} from './legal/cookie-policy/c06-sharing-personal-data-with-other-organisations/c06-sharing-personal-data-with-other-organisations.component';
import {C07HowWeProtectYourPersonalDataComponent} from './legal/cookie-policy/c07-how-we-protect-your-personal-data/c07-how-we-protect-your-personal-data.component';
import {C08HowLongWeUsePersonalDataForComponent} from './legal/cookie-policy/c08-how-long-we-use-personal-data-for/c08-how-long-we-use-personal-data-for.component';
import {C09CookiesAndSimilarTechnologiesComponent} from './legal/cookie-policy/c09-cookies-and-similar-technologies/c09-cookies-and-similar-technologies.component';
import {C10ThirdPartiesComponent} from './legal/cookie-policy/c10-third-parties/c10-third-parties.component';
import {C11YourChoicesComponent} from './legal/cookie-policy/c11-your-choices/c11-your-choices.component';
import {C12SubjectAccessRightsComponent} from './legal/cookie-policy/c12-subject-access-rights/c12-subject-access-rights.component';
import {C13OtherDataProtectionRightsComponent} from './legal/cookie-policy/c13-other-data-protection-rights/c13-other-data-protection-rights.component';
import {C14HowToContactUsComponent} from './legal/cookie-policy/c14-how-to-contact-us/c14-how-to-contact-us.component';
import {C15OurDataProtectionOfficerComponent} from './legal/cookie-policy/c15-our-data-protection-officer/c15-our-data-protection-officer.component';
import {C05aPartnersAndProvidersComponent} from './legal/cookie-policy/c05-sharing-personal-data-with-providers/c05a-partners-and-providers/c05a-partners-and-providers.component';
import {C05a1PartnersComponent} from './legal/cookie-policy/c05-sharing-personal-data-with-providers/c05a-partners-and-providers/c05a1-partners/c05a1-partners.component';
import {C05a2ProvidersComponent} from './legal/cookie-policy/c05-sharing-personal-data-with-providers/c05a-partners-and-providers/c05a2-providers/c05a2-providers.component';
import {C09aCookiePurposesComponent} from './legal/cookie-policy/c09-cookies-and-similar-technologies/c09a-cookie-purposes/c09a-cookie-purposes.component';
import {C09a1ImproveAppsAndWebComponent} from './legal/cookie-policy/c09-cookies-and-similar-technologies/c09a-cookie-purposes/c09a1-improve-apps-and-web/c09a1-improve-apps-and-web.component';
import {C09a2ImprovePerformanceComponent} from './legal/cookie-policy/c09-cookies-and-similar-technologies/c09a-cookie-purposes/c09a2-improve-performance/c09a2-improve-performance.component';
import {C09a3AdvertisingComponent} from './legal/cookie-policy/c09-cookies-and-similar-technologies/c09a-cookie-purposes/c09a3-advertising/c09a3-advertising.component';
import {C09a4MeasuringComponent} from './legal/cookie-policy/c09-cookies-and-similar-technologies/c09a-cookie-purposes/c09a4-measuring/c09a4-measuring.component';
import {C10aThirdPartyTypesComponent} from './legal/cookie-policy/c10-third-parties/c10a-third-party-types/c10a-third-party-types.component';
import {C10a1MeasurementAndPersonalisationComponent} from './legal/cookie-policy/c10-third-parties/c10a-third-party-types/c10a1-measurement-and-personalisation/c10a1-measurement-and-personalisation.component';
import {C10a2ProductRecommendationsComponent} from './legal/cookie-policy/c10-third-parties/c10a-third-party-types/c10a2-product-recommendations/c10a2-product-recommendations.component';
import {C10a3OnlineMarketingComponent} from './legal/cookie-policy/c10-third-parties/c10a-third-party-types/c10a3-online-marketing/c10a3-online-marketing.component';
import {C10a4SocialMediaComponent} from './legal/cookie-policy/c10-third-parties/c10a-third-party-types/c10a4-social-media/c10a4-social-media.component';
import {C10a5CommentingComponent} from './legal/cookie-policy/c10-third-parties/c10a-third-party-types/c10a5-commenting/c10a5-commenting.component';
import {C10a6DeliveringAdsForOurRetailPartnersComponent} from './legal/cookie-policy/c10-third-parties/c10a-third-party-types/c10a6-delivering-ads-for-our-retail-partners/c10a6-delivering-ads-for-our-retail-partners.component';
import {C10a7SecurityOfOurWebsitesAndAppsComponent} from './legal/cookie-policy/c10-third-parties/c10a-third-party-types/c10a7-security-of-our-websites-and-apps/c10a7-security-of-our-websites-and-apps.component';
import {C11aAllChoicesComponent} from './legal/cookie-policy/c11-your-choices/c11a-all-choices/c11a-all-choices.component';
import {C11A1WebBrowserCookiesComponent} from './legal/cookie-policy/c11-your-choices/c11a-all-choices/c11-a1-web-browser-cookies/c11-a1-web-browser-cookies.component';
import {C11A2MobileAppComponent} from './legal/cookie-policy/c11-your-choices/c11a-all-choices/c11-a2-mobile-app/c11-a2-mobile-app.component';
import {C11A3ManagingYourCookiePreferencesComponent} from './legal/cookie-policy/c11-your-choices/c11a-all-choices/c11-a3-managing-your-cookie-preferences/c11-a3-managing-your-cookie-preferences.component';
import {C02aCollectedDataTypesComponent} from './legal/cookie-policy/c02-personal-data-we-collect/c02a-collected-data-types/c02a-collected-data-types.component';
import {C02A1TypesOfDataComponent} from './legal/cookie-policy/c02-personal-data-we-collect/c02a-collected-data-types/c02-a1-types-of-data/c02-a1-types-of-data.component';
import {C02A2WhenYouRegisterForOurServicesComponent} from './legal/cookie-policy/c02-personal-data-we-collect/c02a-collected-data-types/c02-a2-when-you-register-for-our-services/c02-a2-when-you-register-for-our-services.component';
import {C02A3WhenYouShopWithUsOnlineComponent} from './legal/cookie-policy/c02-personal-data-we-collect/c02a-collected-data-types/c02-a3-when-you-shop-with-us-online/c02-a3-when-you-shop-with-us-online.component';
import {C02A4WhenYouContactComponent} from './legal/cookie-policy/c02-personal-data-we-collect/c02a-collected-data-types/c02-a4-when-you-contact/c02-a4-when-you-contact.component';
import {C02A5OtherSourcesOfPersonalDataComponent} from './legal/cookie-policy/c02-personal-data-we-collect/c02a-collected-data-types/c02-a5-other-sources-of-personal-data/c02-a5-other-sources-of-personal-data.component';
import {FooterComponent} from './footer/footer.component';
import {HomePageComponent} from './home-page/home-page.component';
import {HomePageCardComponent} from './home-page/home-page-card/home-page-card.component';
import {RegisterPageComponent} from './register-page/register-page.component';
import {SearchResultsComponent} from './search-results/search-results.component';
import {SearchResultCardComponent} from './search-results/search-result-card/search-result-card.component';
import {PatternDetailComponent} from './pattern-detail/pattern-detail.component';
import {SimpleEllipsizeToggleComponent} from './simple-ellipsize-toggle/simple-ellipsize-toggle.component';
import {DimensionsCalculatorComponent} from './dimensions-calculator/dimensions-calculator.component';
import {SwatchSelectorComponent} from './swatch-selector/swatch-selector.component';
import {HttpClientModule} from '@angular/common/http';
import {PatternActionsComponent} from './pattern-actions/pattern-actions.component';
import {ShoppingCartComponent} from './shopping-cart/shopping-cart.component';
import {ShoppingCartItemComponent} from './shopping-cart/shopping-cart-item/shopping-cart-item.component';
import {UserAccountVerifyComponent} from './user-account-verify/user-account-verify.component';
import {OnlineValidatedEmailComponent} from './form-reusable-components/textboxes/online-validated-email/online-validated-email.component';
import {OnlineValidatedPasswordComponent} from './form-reusable-components/textboxes/online-validated-password/online-validated-password.component';
import {PrimaryActionComponent} from './form-reusable-components/buttons/primary-action/primary-action.component';
import {UserHomePageComponent} from './user-home-page/user-home-page.component';
import {ResendValidationEmailComponent} from './resend-validation-email/resend-validation-email.component';
import {TermsAndConditionsCheckboxComponent} from './form-reusable-components/checkboxes/terms-and-conditions-checkbox/terms-and-conditions-checkbox.component';
import {NewsletterDropdownboxComponent} from './form-reusable-components/dropdown-boxes/newsletter-dropdownbox/newsletter-dropdownbox.component';
import {CancelActionComponent} from './form-reusable-components/buttons/cancel-action/cancel-action.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {OnlineValidatedUsernameTextboxComponent} from './form-reusable-components/textboxes/online-validated-username-textbox/online-validated-username-textbox.component';
import {EmailSubjectComponent} from './form-reusable-components/textboxes/email-subject/email-subject.component';
import {EmailPlainTextBodyComponent} from './form-reusable-components/textboxes/email-plain-text-body/email-plain-text-body.component';
import {CenteredSpinnerComponent} from './spinners/centered-spinner/centered-spinner.component';
import {ModalComponent} from './form-reusable-components/modal/modal/modal.component';
import {UnsubscribeEmailShareComponent} from './email-share-subscription-manager/unsubscribe-email-share/unsubscribe-email-share.component';
import {ManageFavouritesComponent} from './pattern-actions/manage-favourites/manage-favourites.component';
import {FlashMessageDismissableSuccessComponent} from './form-reusable-components/flash-messages/dismissable/success/flash-message-dismissable-success.component';
import {FavouritesCountComponent} from './top-navigation-bar/favourites-count/favourites-count.component';
import {SentenceCasePipe} from './utils/pipes/sentence-case/sentence-case.pipe';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AdvancedSearchComponent} from './advanced-search/advanced-search.component';
import {PlainTextMaxLengthComponent} from './form-reusable-components/textboxes/plain-text-max-length/plain-text-max-length/plain-text-max-length.component';
import {SuccessModalComponent} from './form-reusable-components/modal/success-modal/success-modal.component';
import {CheckoutComponent} from './checkout/checkout.component';
import {BasicTooltipComponent} from './form-reusable-components/tooltips/basic-tooltip/basic-tooltip.component';
import {ShoppingCartTotalComponent} from './shopping-cart/shopping-cart-total/shopping-cart-total.component';
import {AddFreePatternComponent} from './pattern-detail/add-free-pattern/add-free-pattern.component';
import {LoginFirstToAddFreeTemplatePopupComponent} from './pattern-detail/login-first-popup/login-first-to-add-free-template-popup.component';
import {GenericErrorModalComponent} from './form-reusable-components/modal/generic-error-modal/generic-error-modal.component';
import {AbstractSelfContainedModalComponent} from './form-reusable-components/modal/abstract-self-contained-modal/abstract-self-contained-modal.component';
import {AccountDetailsComponent} from './user-home-page/account-details/account-details.component';
import {CollectionComponent} from './user-home-page/collection/collection.component';
import {FormsModule} from '@angular/forms';
import {CollectionItemComponent} from './user-home-page/collection/collection-item/collection-item.component';
import {DownloadDetailsComponent} from './user-home-page/collection/download-details/download-details.component';
import {CommonDownloadAreaComponent} from './common-download-area/common-download-area.component';
import {PdfVerificationComponent} from './pdf-verification/pdf-verification.component';
import {RevisitSearchIdComponent} from './search-results/revisit-search-id/revisit-search-id.component';
import {ShareTemplateOrSearchSuccessComponent} from './form-reusable-components/modal/share-template-or-search-success/share-template-or-search-success.component';
import {ResubscribeEmailShareComponent} from './email-share-subscription-manager/resubscribe-email-share/resubscribe-email-share.component';
import {SharingBussyComponent} from './pattern-actions/sharing-bussy/sharing-bussy.component';
import {SlideComponent} from './home-page/carousel/slide/slide.component';
import {AdminComponent} from './admin/admin.component';
import {TagAdminComponent} from './admin/tag-admin/tag-admin.component';
import {TagRowComponent} from './admin/tag-admin/tag-row/tag-row.component';
import {ProjectAdminComponent} from './admin/project-admin/project-admin.component';
import {StandardApiErrorModalComponent} from './form-reusable-components/modal/standard-api-error-modal/standard-api-error-modal.component';
import {GenericMultiSelectComponent} from './form-reusable-components/dropdown-boxes/generic-multi-select/generic-multi-select.component';
import {TemplateAdminComponent} from './admin/template-admin/template-admin.component';
import {TemplateRowComponent} from './admin/template-admin/template-row/template-row.component';
import {FrequentlyAskedQuestionsComponent} from './frequently-asked-questions/frequently-asked-questions.component';

@NgModule({
   declarations: [
      AppComponent,
      TopNavigationBarComponent,
      CommonNavBarRightComponent,
      CommonSearchBarComponent,
      LogInPageComponent,
      ForgotPasswordComponent,
      CookiePolicyComponent,
      C01WhatThisPolicyCoversComponent,
      C02PersonalDataWeCollectComponent,
      C03WhyWeCollectTheDataComponent,
      C04LegitimateUseComponent,
      C05SharingPersonalDataWithProvidersComponent,
      C06SharingPersonalDataWithOtherOrganisationsComponent,
      C07HowWeProtectYourPersonalDataComponent,
      C08HowLongWeUsePersonalDataForComponent,
      C09CookiesAndSimilarTechnologiesComponent,
      C10ThirdPartiesComponent,
      C11YourChoicesComponent,
      C12SubjectAccessRightsComponent,
      C13OtherDataProtectionRightsComponent,
      C14HowToContactUsComponent,
      C15OurDataProtectionOfficerComponent,
      C05aPartnersAndProvidersComponent,
      C05a1PartnersComponent,
      C05a2ProvidersComponent,
      C09aCookiePurposesComponent,
      C09a1ImproveAppsAndWebComponent,
      C09a2ImprovePerformanceComponent,
      C09a3AdvertisingComponent,
      C09a4MeasuringComponent,
      C10aThirdPartyTypesComponent,
      C10a1MeasurementAndPersonalisationComponent,
      C10a2ProductRecommendationsComponent,
      C10a3OnlineMarketingComponent,
      C10a4SocialMediaComponent,
      C10a5CommentingComponent,
      C10a6DeliveringAdsForOurRetailPartnersComponent,
      C10a7SecurityOfOurWebsitesAndAppsComponent,
      C11aAllChoicesComponent,
      C11A1WebBrowserCookiesComponent,
      C11A2MobileAppComponent,
      C11A3ManagingYourCookiePreferencesComponent,
      C02aCollectedDataTypesComponent,
      C02A1TypesOfDataComponent,
      C02A2WhenYouRegisterForOurServicesComponent,
      C02A3WhenYouShopWithUsOnlineComponent,
      C02A4WhenYouContactComponent,
      C02A5OtherSourcesOfPersonalDataComponent,
      FooterComponent,
      HomePageComponent,
      HomePageCardComponent,
      RegisterPageComponent,
      SearchResultsComponent,
      SearchResultCardComponent,
      PatternDetailComponent,
      SimpleEllipsizeToggleComponent,
      DimensionsCalculatorComponent,
      SwatchSelectorComponent,
      PatternActionsComponent,
      ShoppingCartComponent,
      ShoppingCartItemComponent,
      UserAccountVerifyComponent,
      OnlineValidatedEmailComponent,
      OnlineValidatedPasswordComponent,
      PrimaryActionComponent,
      UserHomePageComponent,
      ResendValidationEmailComponent,
      TermsAndConditionsCheckboxComponent,
      OnlineValidatedUsernameTextboxComponent,
      NewsletterDropdownboxComponent,
      CancelActionComponent,
      ContactUsComponent,
      EmailSubjectComponent,
      EmailPlainTextBodyComponent,
      CenteredSpinnerComponent,
      ModalComponent,
      UnsubscribeEmailShareComponent,
      ResubscribeEmailShareComponent,
      ManageFavouritesComponent,
      FlashMessageDismissableSuccessComponent,
      FavouritesCountComponent,
      SentenceCasePipe,
      AdvancedSearchComponent,
      PlainTextMaxLengthComponent,
      SuccessModalComponent,
      CheckoutComponent,
      BasicTooltipComponent,
      ShoppingCartTotalComponent,
      AddFreePatternComponent,
      LoginFirstToAddFreeTemplatePopupComponent,
      GenericErrorModalComponent,
      AbstractSelfContainedModalComponent,
      AccountDetailsComponent,
      CollectionComponent,
      CollectionItemComponent,
      DownloadDetailsComponent,
      CommonDownloadAreaComponent,
      PdfVerificationComponent,
      RevisitSearchIdComponent,
      ShareTemplateOrSearchSuccessComponent,
      SharingBussyComponent,
      SlideComponent,
      AdminComponent,
      TagAdminComponent,
      TagRowComponent,
      ProjectAdminComponent,
      StandardApiErrorModalComponent,
      GenericMultiSelectComponent,
      TemplateAdminComponent,
      TemplateRowComponent,
      FrequentlyAskedQuestionsComponent
   ],
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      HttpClientModule,
      InfiniteScrollModule,
      FormsModule
   ],
   providers: [
      BrowserAnimationsModule
   ],
   bootstrap: [AppComponent]
})

export class AppModule {
}
