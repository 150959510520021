import {Component, OnInit} from '@angular/core';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';
import {environment} from '../../environments/environment';

@Component({
   selector: 'app-footer',
   templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

   webmasterEmailAddress = environment.email_addresses_imported.default_email_address;
   translationSheet: TranslationSheet;
   registerRoute = environment.frontEndRoutes.registerRoute;
   environment = environment;
   branding = environment.branding.companyName;

   constructor(public dictionary: StaticDictionaryService) {
      this.translationSheet = dictionary.getTranslationSheet();
   }

   ngOnInit(): void {
   }

   getCurrentYear(): number {
      return new Date().getFullYear();
   }
}
