import {GoogleMerchant} from '../type/sales-channels/google-merchant';
import {AbstractSalesChannel} from '../type/sales-channels/abstract-sales-channel';
import {Website} from '../type/sales-channels/website';
import {UnknownSalesChannel} from '../type/sales-channels/unknown-sales-channel';
import {Facebook} from '../type/sales-channels/facebook';
import {Pinterest} from '../type/sales-channels/pinterest';

export class SalesChannelFactory {
   static constructFromDbId(salesChannelDbId: number): AbstractSalesChannel {
      const google = new GoogleMerchant();
      const website = new Website();
      const facebook = new Facebook();
      const pinterest = new Pinterest();

      switch (salesChannelDbId) {
         case google.getDbId():
            return google;
         case website.getDbId():
            return website;
         case pinterest.getDbId():
            return pinterest;
         case facebook.getDbId():
            return facebook;
         default:
            return new UnknownSalesChannel();
      }
   }
}
