import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {English} from '../../locale/language-detector/type/english';
import {Spanish} from '../../locale/language-detector/type/spanish';
import {French} from '../../locale/language-detector/type/french';
import {AbstractLanguage} from '../../locale/language-detector/type/abstract-language';

@Injectable({
   providedIn: 'root'
})

export class SetCanonicalLinkService {

   private renderer: Renderer2;

   private static rebuildUrl(baseUrl: string, parser: URLSearchParams): string {
      const queryStringWithoutLanguageParameter = parser.toString().trim();

      let url = baseUrl;

      if (queryStringWithoutLanguageParameter.length > 0) {
         url = url + '?' + queryStringWithoutLanguageParameter;
      }

      return url;
   }

   private static getBaseUrlWithoutForwardSlash(): string {
      const fullRoute = window.location.href;
      let withoutQueryString = fullRoute.split('?')[0];

      // We do this twice, to remove any combination of /? or ?/;
      for (let i = 0; i < 2; i++) {
         if (withoutQueryString.endsWith('/')) {
            withoutQueryString = withoutQueryString.slice(0, -1);
         }
         if (withoutQueryString.endsWith('?')) {
            withoutQueryString = withoutQueryString.slice(0, -1);
         }
      }

      return withoutQueryString;
   }

   private static removeLanguageParameter(qs: URLSearchParams): void {
      qs.delete(environment.queryStringParameterNames.languageLiteral);
   }

   private static addLanguageParameter(qs: URLSearchParams, lan: AbstractLanguage): void {
      SetCanonicalLinkService.removeLanguageParameter(qs);
      qs.set(environment.queryStringParameterNames.languageLiteral, lan.getApiLiteral());
   }

   private static getQueryStringParameters(): URLSearchParams {
      const queryString = window.location.search;
      return new URLSearchParams(queryString);
   }

   constructor(
      rendererFactory: RendererFactory2
   ) {
      this.renderer = rendererFactory.createRenderer(null, null);
   }

   /**
    * Chat GPT's courtesy, with some modifications of my own
    */
   setCanonicalUrlBasedOnCurrentRoute(): void {

      const baseUrl = SetCanonicalLinkService.getBaseUrlWithoutForwardSlash();
      const parser = SetCanonicalLinkService.getQueryStringParameters();

      this.setCanonicalAttribute(baseUrl, parser);
      this.setAlternateLanguages(baseUrl, parser);
   }

   setCanonicalAttributeForUrl(url: string): void {
      const link: HTMLLinkElement = this.renderer.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', url);

      const existingLink = document.querySelector('link[rel="canonical"]');

      if (existingLink) {
         this.renderer.removeChild(document.head, existingLink);
      }

      this.renderer.appendChild(document.head, link);
   }

   private setCanonicalAttribute(baseUrl: string, parser: URLSearchParams): void {
      SetCanonicalLinkService.removeLanguageParameter(parser);
      const url = SetCanonicalLinkService.rebuildUrl(baseUrl, parser);

      this.setCanonicalAttributeForUrl(url);
   }

   /**
    * ChatGPT's courtesy, with my own modifications
    */
   private setAlternateLanguages(baseUrl: string, parser: URLSearchParams) {

      this.removeAllAlternateLinks();

      const availableLanguages = [
         new English(),
         new Spanish(),
         new French()
      ];

      availableLanguages.forEach((l: AbstractLanguage) => {
         SetCanonicalLinkService.addLanguageParameter(parser, l);

         this.setAlternateAttributeForUrlAndLanguage(SetCanonicalLinkService.rebuildUrl(baseUrl, parser), l);
      });
   }

   setAlternateAttributeForUrlAndLanguage(url: string, l: AbstractLanguage): void {
      let alternate: HTMLLinkElement;
      alternate = this.renderer.createElement('link');
      alternate.setAttribute('rel', 'alternate');
      alternate.setAttribute('hreflang', l.getApiLiteral());
      alternate.setAttribute('href', url);
      this.renderer.appendChild(document.head, alternate);
   }

   /**
    * ChatGPT's courtesy
    */
   removeAllAlternateLinks(): void {
      // Get all link rel="alternate" elements
      const alternateLinks = document.querySelectorAll('link[rel="alternate"]');

      alternateLinks.forEach(link => {
         // Remove each link from the document head
         this.renderer.removeChild(document.head, link);
      });
   }
}
