import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SearchPatternsService} from '../service/api-client/search-pattern/search-patterns.service';
import {LanguageDetectorService} from '../service/locale/language-detector/language-detector.service';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';
import {AdvancedSearchStorageService} from './service/advanced-search-storage/advanced-search-storage.service';
import {UserAdvancedSearchParamsObject} from './object/user-advanced-search-params-object';
import {DomUtils} from '../utils/dom/dom-utils';
import {SearchRequest} from '../service/api-client/search-pattern/type/search-request';
import {Router} from '@angular/router';
import {CommonSearchBarComponent} from '../top-navigation-bar/common-search-bar/common-search-bar.component';
import {PrimaryActionComponent} from '../form-reusable-components/buttons/primary-action/primary-action.component';
import {TagDetailsWithChildrenObject} from '../service/api-client/home-page/object/tag-details-with-children.object';
import {TagDetailsObject} from '../service/api-client/home-page/object/tag-details.object';
import {environment} from '../../environments/environment';
import {SetTitleService} from '../service/seo/set-title/set-title.service';
import {SetCanonicalLinkService} from '../service/seo/set-canonical/set-canonical-link.service';

@Component({
   selector: 'app-advanced-search',
   templateUrl: './advanced-search.component.html',
   styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit, OnDestroy, AfterViewInit {
   public tagsTree: TagDetailsWithChildrenObject[] = [];
   public tagsList: TagDetailsObject[] = [];
   public bussy = true;
   public translationSheet: TranslationSheet;
   public storedAdvancedSearchParameters: UserAdvancedSearchParamsObject;

   public treeView = true;

   @ViewChild(PrimaryActionComponent) primaryActionComponent: PrimaryActionComponent | undefined;

   constructor(
      private searchPatternService: SearchPatternsService,
      public languageDetectorService: LanguageDetectorService,
      public staticDictionary: StaticDictionaryService,
      private advancedSearchStorageService: AdvancedSearchStorageService,
      private router: Router,
      private title: SetTitleService,
      private canonical: SetCanonicalLinkService
   ) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   private static resetTagContainers(): void {
      const treeNavContainer = DomUtils.getNullSafeHtmlFormElementById('full-tag-tree-view');
      treeNavContainer.innerHTML = '';

      const listContainer = DomUtils.getNullSafeHtmlFormElementById('full-tag-list-view');
      listContainer.innerHTML = '';
   }

   ngAfterViewInit(): void {
      const interval = setInterval(() => {
         let treeContainer: HTMLElement | null;

         treeContainer = DomUtils.getHtmlElementById('full-tag-tree-view');

         if ((this.tagsTree.length > 0) && (treeContainer !== null)) {
            this.renderFullTagTree(this.tagsTree);
            clearInterval(interval);
         }
      }, 300);
   }

   ngOnInit(): void {

      this.title.setTitle(this.translationSheet.advancedSearch.title);
      this.canonical.setCanonicalUrlBasedOnCurrentRoute();

      const searchBar = DomUtils.getNullSafeHtmlElementById(CommonSearchBarComponent.MAIN_SEARCH_BAR_ID);
      searchBar.classList.add('hidden'); // Or there will be two search bars, which can be confusing, but needs to be
      // reinstated when the user commits the search

      this.searchPatternService.getFullTagTree().subscribe((tagTree: TagDetailsWithChildrenObject[]) => {
         this.storedAdvancedSearchParameters = this.advancedSearchStorageService.readFromStorage();
         this.tagsTree = tagTree;
         this.bussy = false;
      });
   }

   private renderFullTagList(): void {
      if (this.tagsList.length === 0) {
         this.bussy = true;
         this.searchPatternService.getFullTagList().subscribe((l: TagDetailsObject[]) => {
            this.tagsList = l;
            this.bussy = false;
            this.renderFullTagList();
         });
      } else {

         const interval = setInterval(() => {
            let listContainer: HTMLElement | null;

            listContainer = DomUtils.getHtmlElementById('full-tag-list-view');

            if (listContainer !== null) {
               const encapsulationToken = DomUtils.getEncapsulationTokenForElementId('full-tag-list-view');


               this.tagsList.forEach((t: TagDetailsObject) => {
                  listContainer?.appendChild(this.generatePlainHtmlTagElement(t, encapsulationToken, this));
               });

               clearInterval(interval);
            }
         }, 300);

         setTimeout(() => {
            clearInterval(interval);
         }, 10000);
      }
   }

   private renderFullTagTree(treeTag: TagDetailsWithChildrenObject[]): void {

      const tagTreeViewContainer = DomUtils.getNullSafeHtmlElementById('full-tag-tree-view');

      const encapsulationToken = DomUtils.getEncapsulationTokenForElementId('full-tag-tree-view');

      treeTag.forEach((t: TagDetailsWithChildrenObject) => {
         tagTreeViewContainer.appendChild(this.generateTreeHtmlTagElement(t, encapsulationToken, this));
      });
   }

   private generatePlainHtmlTagElement(
      t: TagDetailsObject,
      encapsulationToken: string,
      thatAlias: AdvancedSearchComponent
   ): HTMLElement {
      const badgeContainer = document.createElement('div');
      const input = document.createElement('input');
      const label = document.createElement('label');

      badgeContainer.setAttribute(encapsulationToken, '');
      badgeContainer.setAttribute('id', 'badge-container-' + t.getTagId());
      badgeContainer.setAttribute('base_css_class', t.getCssSubclass());
      input.setAttribute(encapsulationToken, '');
      label.setAttribute(encapsulationToken, '');

      input.setAttribute('type', 'checkbox');

      input.classList.add('form-check-input');
      input.setAttribute('id', 'tag-id-' + t.getTagId());
      input.setAttribute('value', t.getTagId().toString());


      input.addEventListener('click', (ev: MouseEvent) => {
         thatAlias.manageLabelCollection(ev.target as HTMLInputElement);

      });

      label.classList.add('form-check-label');
      label.setAttribute('for', 'tag-id-' + t.getTagId());
      label.textContent =
         t.getTitleForLanguage(this.staticDictionary.getLanguage()) +
         ' (x' + t.getCountProjectsWithSameTag().toString() + ')';

      badgeContainer.classList.add('badge', 'selectable-label', 'form-check', 'form-check-inline');

      if (this.storedAdvancedSearchParameters.hasTagId(t.getTagId())) {
         badgeContainer.classList.add('sl-selected');
         badgeContainer.classList.remove(t.getCssSubclass());
         input.checked = true;
      } else {
         badgeContainer.classList.remove('sl-selected');
         badgeContainer.classList.add(t.getCssSubclass());
      }

      badgeContainer.appendChild(input);
      badgeContainer.appendChild(label);

      return badgeContainer;
   }

   private generateTreeHtmlTagElement(
      t: TagDetailsWithChildrenObject,
      encapsulationToken: string,
      thatAlias: AdvancedSearchComponent
   ): HTMLElement {

      const details = document.createElement('details');
      const summary = document.createElement('summary');

      details.setAttribute(encapsulationToken, '');
      summary.setAttribute(encapsulationToken, '');

      details.classList.add('tree-nav__item');

      if (t.getChildren().length > 0) {
         details.classList.add('is-expandable');
         if (this.atLeastOneDescendantSelected(t.getChildren())) {
            details.setAttribute('open', '');
         }
      }

      summary.classList.add('tree-nav__item-title');

      details.appendChild(summary);

      const badgeContainer = this.generatePlainHtmlTagElement(t, encapsulationToken, thatAlias);

      summary.appendChild(badgeContainer);


      t.getChildren().forEach((c: TagDetailsWithChildrenObject) => {
         details.appendChild(this.generateTreeHtmlTagElement(c, encapsulationToken, thatAlias));
      });

      return details;
   }


   private atLeastOneDescendantSelected(children: TagDetailsWithChildrenObject[]): boolean {

      let result = false;

      children.forEach((c: TagDetailsWithChildrenObject) => {
         if (this.storedAdvancedSearchParameters.hasTagId(c.getTagId())) {
            result = true;
         } else {
            result = result || this.atLeastOneDescendantSelected(c.getChildren());
         }
      });

      return result;
   }

   private manageLabelCollection(tag: HTMLInputElement): void {

      const tagId = tag.value;
      const checked = tag.checked;
      const badgeContainer = DomUtils.getNullSafeHtmlFormElementById('badge-container-' + tagId);

      const baseCssClass = badgeContainer.getAttribute('base_css_class') ?? '';

      if (checked) {
         this.storedAdvancedSearchParameters.addSelectedTagId(parseInt(tagId, 10));
         badgeContainer.classList.add('sl-selected');
         badgeContainer.classList.remove(baseCssClass);


      } else {
         this.storedAdvancedSearchParameters.removeSelectedTagId(parseInt(tagId, 10));
         badgeContainer.classList.remove('sl-selected');
         badgeContainer.classList.add(baseCssClass);
      }

      this.advancedSearchStorageService.writeToStorage(this.storedAdvancedSearchParameters);
   }

   search(): void {
      const searchBar = DomUtils.getNullSafeHtmlElementById(CommonSearchBarComponent.MAIN_SEARCH_BAR_ID);
      // It has been hidden when rendering the advanced search, because we don't want two search forms in the view
      searchBar.classList.remove('hidden');

      if (this.primaryActionComponent === undefined) {
         return;
      }

      this.primaryActionComponent?.setBusy(true);
      this.advancedSearchStorageService.writeToStorage(this.storedAdvancedSearchParameters);
      const searchRequestObject = new SearchRequest(
         this.storedAdvancedSearchParameters.getIncludedTagIds(),
         [],
         this.storedAdvancedSearchParameters.getIncludedKeywordList(),
         undefined,
         undefined,
         0
      );

      this.searchPatternService.searchAndGetTemplateFullInfo(searchRequestObject).subscribe(() => {

         const route = environment.frontEndRoutes.searchResults + '?' + searchRequestObject.serializeAsQueryString();

         this.router.navigateByUrl(route).then(() => {
            CommonSearchBarComponent.searchRequestParametersSubject.next(searchRequestObject);
            this.primaryActionComponent?.setBusy(false);
         });
      });
   }

   private regenereateTreeView(): void {
      AdvancedSearchComponent.resetTagContainers();
      this.renderFullTagTree(this.tagsTree);
   }

   private regenerateListView() {
      AdvancedSearchComponent.resetTagContainers();
      this.renderFullTagList();
   }

   resetSearchParameters(): void {
      this.storedAdvancedSearchParameters.reset();
      this.advancedSearchStorageService.writeToStorage(this.storedAdvancedSearchParameters);

      if (this.treeView) {
         this.regenereateTreeView();
      }
   }

   keywordListChanged() {
      const keywordListControl: HTMLFormElement = DomUtils.getNullSafeHtmlFormElementById('keyword-list-control');

      const value = keywordListControl.value;

      this.storedAdvancedSearchParameters.setRawKeywords(value);
   }

   ngOnDestroy(): void {
      this.advancedSearchStorageService.writeToStorage(this.storedAdvancedSearchParameters);
   }

   toggleTreeView(toggle: boolean): void {
      this.treeView = toggle;

      if (this.treeView) {
         this.regenereateTreeView();
      } else {
         this.regenerateListView();
      }
   }

   navigateHome(): void {
      this.router.navigate(['/']).then(() => {
      });
   }
}
