import {Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {ShoppingCartComponent} from '../shopping-cart/shopping-cart.component';
import {loadScript, OnApproveData, PayPalNamespace} from '@paypal/paypal-js';
import {BuildShoppingCartListService} from '../shopping-cart/service/build-shopping-cart-list-service';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../service/api-client/user/user.service';
import {DomUtils} from '../utils/dom/dom-utils';
import {PaypalService} from '../service/checkout/paypal/paypal.service';
import {OrderCreationDetails} from '../service/checkout/paypal/interfaces/order-creation-details';
import {PaypalMerchantDetails} from '../service/checkout/paypal/interfaces/paypal-merchant-details';
import {OrderCaptureDetails} from '../service/checkout/paypal/interfaces/order-capture-details';
import {ApiClientUtilsService} from '../service/api-client/api-client-utils/api-client-utils.service';
import {ShoppingCartStorageService} from '../service/shopping-cart/shopping-cart-storage.service';
import * as bootstrap from 'bootstrap';
import {CommonNavBarRightComponent} from '../top-navigation-bar/common-nav-bar-right/common-nav-bar-right.component';
import {LanguageDetectorService} from '../service/locale/language-detector/language-detector.service';
import {SetTitleService} from '../service/seo/set-title/set-title.service';
import {SetCanonicalLinkService} from '../service/seo/set-canonical/set-canonical-link.service';

@Component({
   selector: 'app-checkout',
   templateUrl: './checkout.component.html',
   styleUrls: ['./checkout.component.scss']
})

export class CheckoutComponent extends ShoppingCartComponent implements OnInit {

   checkoutSuccessModalId = 'checkout_success';

   checkoutStatuses = {
      paypal: {
         loading: 1,
         loaded: 2,
         genericError: 4,
         purchaseSuccess: 5,
         processing: 6,
         declined: 7
      },
      page: {
         busy: 1,
         ready: 2
      }
   };

   paypalStatus = this.checkoutStatuses.paypal.loading;
   pageStatus = this.checkoutStatuses.page.ready;

   private paypalScriptLoaded = false;

   paymentId = ''; // to be updated when checkout is completed
   emailAddress = ''; // to be updated when checkout is completed

   constructor(
      protected paypalService: PaypalService,
      protected buildShoppingCartListService: BuildShoppingCartListService,
      protected staticDictionaryService: StaticDictionaryService,
      protected router: Router,
      protected user: UserService,
      protected clientUtils: ApiClientUtilsService,
      protected shoppingCartStorage: ShoppingCartStorageService,
      activatedRoute: ActivatedRoute,
      languageDetectorService: LanguageDetectorService,
      title: SetTitleService,
      canonical: SetCanonicalLinkService
   ) {
      super(
         shoppingCartStorage,
         buildShoppingCartListService,
         staticDictionaryService,
         router,
         user,
         activatedRoute,
         languageDetectorService,
         title,
         canonical
      );
   }

   public checkShoppingCartReady(): boolean {
      const q1 = this.loadingShoppingCartStatus === this.loadingShoppingCarStatuses.loaded;

      const q2 = this.shoppingCartList !== null;
      return q1 && q2;
   }

   ngOnInit(): void {
      const interval = setInterval(() => {
         const el = DomUtils.getHtmlElementById('paypal-button-id');
         if (el !== null) {
            clearInterval(interval);
            this.renderPaypalButtons();
         }
      }, 100);

      setTimeout(() => {
         clearInterval(interval);
      }, 5000);
   }

   private renderPaypalButtons(): void {
      if (this.checkShoppingCartReady() && !this.paypalScriptLoaded) {

         this.paypalService.getPaypalMerchantDetails().then((paypalMerchantDetails: PaypalMerchantDetails) => {
            loadScript({
               clientId: paypalMerchantDetails.publicMerchantId,
               components: 'buttons',
               dataPageType: 'checkout',
               currency: paypalMerchantDetails.merchantCurrencyCode
            }).then((paypal: PayPalNamespace) => {
               this.paypalStatus = this.checkoutStatuses.paypal.loaded;
               this.paypalScriptLoaded = true;
               if (paypal) {
                  const buttons = paypal.Buttons;

                  if ((buttons === null) || (buttons === undefined)) {
                     this.clientUtils.logCodeSearchableFrontEndGenericError(
                        'S6ZmjUVKSm2ucsQW',
                        'Paypal turns out to be null or undefined'
                     ).then(
                        () => {
                           this.paypalStatus = this.checkoutStatuses.paypal.genericError;
                        });
                  } else {

                     const thisAlias = this;

                     if ((thisAlias.shoppingCartList === null) || (thisAlias.shoppingCartList.countItems() === 0)) {
                        thisAlias.router.navigateByUrl(environment.frontEndRoutes.shoppingCartRoute).then(() => {
                        });
                        return;
                     }

                     buttons({
                        style: {
                           color: 'gold',
                           layout: 'vertical',
                           height: 38
                        },
                        createOrder() {

                           return thisAlias.paypalService.createCheckoutOrder(
                              thisAlias.shoppingCartList,
                              environment.xstitch_front_end_url_base + environment.frontEndRoutes.checkoutSuccess,
                              environment.xstitch_front_end_url_base + environment.frontEndRoutes.shoppingCartRoute
                           ).then((r: OrderCreationDetails) => {
                              return r.id;
                           });
                        },

                        onApprove(data: OnApproveData) {
                           thisAlias.paypalStatus = thisAlias.checkoutStatuses.paypal.processing;
                           return thisAlias.paypalService.captureOrder(data.orderID)
                              .then((orderData: OrderCaptureDetails) => {
                                 if ((orderData.paymentId === undefined) || (orderData.paymentId === null)) {
                                    thisAlias.paypalStatus = thisAlias.checkoutStatuses.paypal.declined;
                                 } else {
                                    thisAlias.paypalStatus = thisAlias.checkoutStatuses.paypal.purchaseSuccess;
                                    thisAlias.paymentId = orderData.paymentId;
                                    thisAlias.emailAddress = orderData.emailAddress;
                                    thisAlias.showSuccessModal();
                                 }
                              });
                        },
                        onError(err: Record<string, unknown>) {
                           thisAlias.clientUtils.logCodeSearchableFrontEndGenericError('nBYS8hShZ6DQYU26', JSON.stringify(err, null, 2)).then(() => {
                              thisAlias.paypalStatus = thisAlias.checkoutStatuses.paypal.genericError;
                           });
                        }

                     }).render('#paypal-button-id').then(() => {
                        this.paypalStatus = thisAlias.checkoutStatuses.paypal.loaded;
                     }).catch((error) => {
                        thisAlias.clientUtils.logCodeSearchableFrontEndGenericError('Ba6tGTeBngw0nYHJ', JSON.stringify(error, null, 2)).then(() => {
                           thisAlias.paypalStatus = thisAlias.checkoutStatuses.paypal.genericError;
                        });
                     });
                  }
               } else {
                  this.clientUtils.logCodeSearchableFrontEndGenericError(
                     '4WL7B3D73qVt74Zk',
                     'could not load the paypal component'
                  ).then(() => {
                     this.paypalStatus = this.checkoutStatuses.paypal.genericError;
                  });
               }
            }).catch((error) => {
               this.clientUtils.logCodeSearchableFrontEndGenericError('AzKrSiskGF7PwU8a', JSON.stringify(error, null, 2)).then(() => {
                  this.paypalStatus = this.checkoutStatuses.paypal.genericError;
               });
            });
         }).catch((error) => {
            this.clientUtils.logCodeSearchableFrontEndGenericError('Wu7Z6guLLKpVlw9Z', JSON.stringify(error, null, 2)).then(() => {
               this.paypalStatus = this.checkoutStatuses.paypal.genericError;
            });
         });
      }
   }

   shouldEnablePaymentButtons(): boolean {

      if (this.paypalStatus === this.checkoutStatuses.paypal.genericError) {
         return false;
      }

      return this.paypalStatus !== this.checkoutStatuses.paypal.processing;
   }

   showSuccessModal(): void {
      const modalComponent = DomUtils.getNullSafeHtmlElementById(this.checkoutSuccessModalId);
      const modal = new bootstrap.Modal(modalComponent);
      modal.show();
   }

   modalClosed(): void {

      this.pageStatus = this.checkoutStatuses.page.busy;

      this.shoppingCartStorage.resetShoppingCartContent();
      CommonNavBarRightComponent.updateShoppingCartCount(0);

      this.user.getCurrentUser().subscribe(() => {
         this.router.navigateByUrl(environment.frontEndRoutes.accountHome).then();
      }, () => {
         this.router.navigateByUrl(environment.frontEndRoutes.home).then();
      });
   }

   getBranding(): string {
      return environment.branding.companyName;
   }
}
