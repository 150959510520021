import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import * as bootstrap from 'bootstrap';
import {UserService} from '../service/api-client/user/user.service';
import {environment} from '../../environments/environment';
import {FormCommonFunctionality} from '../form-reusable-components/form-common-functionality';
import {OnlineValidatedEmailComponent} from '../form-reusable-components/textboxes/online-validated-email/online-validated-email.component';
import {OnlineValidatedPasswordComponent} from '../form-reusable-components/textboxes/online-validated-password/online-validated-password.component';
import {PrimaryActionComponent} from '../form-reusable-components/buttons/primary-action/primary-action.component';
import {NewsletterDropdownboxComponent} from '../form-reusable-components/dropdown-boxes/newsletter-dropdownbox/newsletter-dropdownbox.component';
import {OnlineValidatedUsernameTextboxComponent} from '../form-reusable-components/textboxes/online-validated-username-textbox/online-validated-username-textbox.component';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {Router} from '@angular/router';
import {DomUtils} from '../utils/dom/dom-utils';
import {StandardApiErrorResponse} from '../service/api-client/xstitch-api-gateway/type/standard-api-error-response';
import {SetTitleService} from '../service/seo/set-title/set-title.service';
import {SetCanonicalLinkService} from '../service/seo/set-canonical/set-canonical-link.service';

@Component({
   selector: 'app-register-page',
   templateUrl: './register-page.component.html'
})
export class RegisterPageComponent implements OnInit, AfterViewInit {
   @ViewChild(OnlineValidatedEmailComponent) onlineValidatedEmailComponent: OnlineValidatedEmailComponent;
   @ViewChild(OnlineValidatedPasswordComponent) onlineValidatedPasswordComponent: OnlineValidatedPasswordComponent;
   @ViewChild(PrimaryActionComponent) primaryActionComponent: PrimaryActionComponent;
   @ViewChild(OnlineValidatedUsernameTextboxComponent) usernameTextBoxComponent: OnlineValidatedUsernameTextboxComponent;
   @ViewChild(NewsletterDropdownboxComponent) newsletterDropdownboxComponent: NewsletterDropdownboxComponent;

   private registerFormModal: bootstrap.Modal;

   isWellFormedEmail = false;
   isWellFormedPassword = false;
   isTermsAndConditionsValid = false;
   isValidUserName = false;
   isValidNewsletter = false;

   translationSheet: TranslationSheet;

   registerPageStatus: number;

   registerPageStatuses = {
      landing_page: 1,
      unknown_error: 2,
      success: 3
   };

   loginRoute = environment.frontEndRoutes.loginRoute;

   private static getRegisterFormModal(): HTMLElement {
      return DomUtils.getNullSafeHtmlElementById('register-modal-toggle');
   }

   constructor(
      private router: Router,
      private userService: UserService,
      private staticDictionary: StaticDictionaryService,
      private title: SetTitleService,
      private canonical: SetCanonicalLinkService
   ) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   private setTitle(): void {
      this.title.setTitle(this.translationSheet.registerComponent.newAccount);
      this.canonical.setCanonicalUrlBasedOnCurrentRoute();
   }

   ngAfterViewInit(): void {
      this.setTitle();
   }

   ngOnInit(): void {
      this.setTitle();
      this.registerPageStatus = this.registerPageStatuses.landing_page;
      this.registerFormModal = new bootstrap.Modal(RegisterPageComponent.getRegisterFormModal());
      this.registerFormModal.show();
   }

   isFormValidated(): boolean {
      return (
         this.isValidUserName &&
         this.isWellFormedEmail &&
         this.isWellFormedPassword &&
         this.isValidNewsletter &&
         this.isTermsAndConditionsValid
      );
   }

   async validateRegistration() {

      this.primaryActionComponent.setBusy(true);

      FormCommonFunctionality.disableFormFieldSetById('registration-form-fieldset');

      this.userService.registerNewUser(
         this.usernameTextBoxComponent.getWellFormedUsername(),
         this.onlineValidatedEmailComponent.getWellFormedEmail(),
         this.onlineValidatedPasswordComponent.getWellFormedPassword(),
         this.newsletterDropdownboxComponent.getValidatedNewsletterValue()
      ).subscribe(() => {
         this.registerPageStatus = this.registerPageStatuses.success;
         FormCommonFunctionality.enableFormFieldSetById('registration-form-fieldset');
         this.primaryActionComponent.setBusy(false);
      }, (error: StandardApiErrorResponse) => {
         FormCommonFunctionality.enableFormFieldSetById('registration-form-fieldset');
         this.primaryActionComponent.setBusy(false);
         if (error.getCode() === environment.xstitch_api_error_codes.users.user_with_same_email_exists) {
            const logInRoute = environment.frontEndRoutes.loginRoute;
            const placeHolder = {name: 'logInRoute', value: logInRoute};
            const expanded = this.translationSheet.registerComponent.sameEmailExists.expand([placeHolder]);
            this.onlineValidatedEmailComponent.setExternalErrorAsHtml(expanded);
         } else {
            this.registerPageStatus = this.registerPageStatuses.unknown_error;
         }
      });
   }

   tryRegistrationAgain() {
      this.registerPageStatus = this.registerPageStatuses.landing_page;
   }

   redirectHome() {
      this.registerFormModal.hide();
      this.router.navigate(['/']).then();
   }
}
