import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';

@Injectable({
   providedIn: 'root'
})
export class SetTitleService {

   constructor(private title: Title) {
   }

   public setTitle(title: string | undefined): void {
      let sanitized: string;

      let defaultTitle = environment.branding.companyName;

      if (title) {
         sanitized = title.trim();

         if (sanitized.length > 0) {
            defaultTitle = sanitized;
         }
      }


      this.title.setTitle(defaultTitle);
   }
}
