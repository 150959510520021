/* tslint:disable:no-string-literal */
import {AfterViewChecked, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SearchPatternsService} from '../service/api-client/search-pattern/search-patterns.service';
import {ProjectFullInfo} from '../service/api-client/project/type/project-full-info';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {CalculatorsService} from '../service/api-client/calculators/calculators.service';
import * as bootstrap from 'bootstrap';
import {ShoppingCartStorageService} from '../service/shopping-cart/shopping-cart-storage.service';
import {ShoppingCartItem} from '../service/shopping-cart/object/shopping-cart-item';
import {CommonNavBarRightComponent} from '../top-navigation-bar/common-nav-bar-right/common-nav-bar-right.component';
import {DeadlineCalculatorResponseApi} from '../service/api-client/calculators/object/deadline-calculator-response-api';
import {LanguageDetectorService} from '../service/locale/language-detector/language-detector.service';
import {ProjectTemplate} from '../service/api-client/search-pattern/type/project-template';
import {DomUtils} from '../utils/dom/dom-utils';
import {TranslationSheet} from '../service/locale/static-dictionary/type/translation-sheet';
import {StaticDictionaryService} from '../service/locale/static-dictionary/static-dictionary.service';
import {UserService} from '../service/api-client/user/user.service';
import {FavouritesManagerService} from '../service/favourites-service/favourites-manager.service';
import {PatternFavouritesContainer} from '../service/favourites-service/type/pattern-favourites-container';
import {SearchRequest} from '../service/api-client/search-pattern/type/search-request';
import {CommonSearchBarComponent} from '../top-navigation-bar/common-search-bar/common-search-bar.component';
import {PublicProjectComment} from '../service/api-client/search-pattern/type/public-project-comment';
import {PlainTextMaxLengthComponent} from '../form-reusable-components/textboxes/plain-text-max-length/plain-text-max-length/plain-text-max-length.component';
import {PrimaryActionComponent} from '../form-reusable-components/buttons/primary-action/primary-action.component';
import {environment} from '../../environments/environment';
import {LoginFirstToAddFreeTemplatePopupComponent} from './login-first-popup/login-first-to-add-free-template-popup.component';
import {GenericErrorModalComponent} from '../form-reusable-components/modal/generic-error-modal/generic-error-modal.component';
import {ApiClientUtilsService} from '../service/api-client/api-client-utils/api-client-utils.service';
import {StandardApiErrorResponse} from '../service/api-client/xstitch-api-gateway/type/standard-api-error-response';
import {User} from '../service/api-client/user/type/user';
import {SetTitleService} from '../service/seo/set-title/set-title.service';
import {SetCanonicalLinkService} from '../service/seo/set-canonical/set-canonical-link.service';
import {English} from '../service/locale/language-detector/type/english';
import {French} from '../service/locale/language-detector/type/french';
import {Spanish} from '../service/locale/language-detector/type/spanish';
import {ExternalClickService} from '../service/external-click/external-click.service';
import {ExternalClickDetails} from '../service/external-click/type/external-click-details';

@Component({
   selector: 'app-pattern-detail',
   templateUrl: './pattern-detail.component.html',
   styleUrls: ['./pattern-detail.component.scss']
})
export class PatternDetailComponent implements OnInit, AfterViewChecked {
   @ViewChild(PlainTextMaxLengthComponent) plainTextMaxLengthComponent: PlainTextMaxLengthComponent;
   @ViewChild('send_comment_button_id') sendCommentButton: PrimaryActionComponent;
   @ViewChild(LoginFirstToAddFreeTemplatePopupComponent) logInFirstPopup: LoginFirstToAddFreeTemplatePopupComponent;
   @ViewChild(GenericErrorModalComponent) genericErrorModal: GenericErrorModalComponent;
   @ViewChild('addFreePatterToCollection') addFreePatternToCollectionButton: PrimaryActionComponent;

   logInToAddToCollectionPopupId = 'log-in-to-add-to-collection-1';
   patternDeadlineCalculationFormModalId = 'pattern-deadline-calculator-result';
   patternDeadlineCalculatorFormId = 'pattern-deadline-calculator-form';
   patternDeadlineCalculatorErrorModalId = 'pattern-deadline-calculator-error';
   leaveACommentFormModalId = 'leave-a-comment-form';
   commentSentSuccessModalId = 'comment-sent-success';
   genericErrorModalId = 'generic-error-modal-id';

   commentValidated = false;
   commentSent = false;

   pageStatuses = {
      loading: 0,
      ok: 1
   };

   pageStatus: number;

   pattern: ProjectFullInfo;
   safeImage: SafeUrl;
   notes: string | null;

   stitchesPerHour: number;
   hoursADay: number;
   daysAWeek: number;
   stitchCount: number;

   userIsLoggedIn = false;
   patternDeadline: string;
   userIsAdmin = false;

   patternDeadlineCalculatorModal: bootstrap.Modal | null = null;
   patternDeadlineCalculatorResult: bootstrap.Modal | null = null;
   patternDeadlineCalculatorError: bootstrap.Modal | null = null;
   leaveACommentModal: bootstrap.Modal | null = null;
   inShoppingCart: boolean;
   selectedTemplate: ProjectTemplate | null = null;
   translationSheet: TranslationSheet;
   swatchSelectorEventAdded = false;
   isFavouriteProject = false;
   publicProjectComments: PublicProjectComment[] | null = null;

   public static getAddTemplateUrl(templateId: number): string {

      const url = environment.frontEndRoutes.addFreeTemplateToCollection;

      return url.replace(':template-id', templateId.toString());
   }

   public static getLogInSuccessRouteWithSuccessRedirectUrl(templateId: number): string {
      return environment.frontEndRoutes.loginRoute + '?' +
         environment.queryStringParameterNames.successRedirect +
         '=' + PatternDetailComponent.getAddTemplateUrl(templateId);

   }

   constructor(
      private activatedRoute: ActivatedRoute,
      private searchPatternService: SearchPatternsService,
      private router: Router,
      private domSanitizer: DomSanitizer,
      private calculatorService: CalculatorsService,
      private shoppingCartStorageService: ShoppingCartStorageService,
      public languageDetectorService: LanguageDetectorService,
      public staticDictionary: StaticDictionaryService,
      private userService: UserService,
      private favouritesService: FavouritesManagerService,
      private apiClientUtils: ApiClientUtilsService,
      private title: SetTitleService,
      private canonical: SetCanonicalLinkService,
      private externalClick: ExternalClickService
   ) {
      this.translationSheet = staticDictionary.getTranslationSheet();
   }

   private static _setFormComponentValid(component: HTMLElement): void {
      PatternDetailComponent._resetFormComponentValidation(component);
      component.classList.add('is-valid');

   }

   private static _setFormComponentInvalid(component: HTMLElement): void {
      PatternDetailComponent._resetFormComponentValidation(component);
      component.classList.add('is-invalid');
   }

   private static _resetFormComponentValidation(component: HTMLElement): void {
      component.classList.remove('is-valid');
      component.classList.remove('is-invalid');
   }

   private static _getStitchesPerHourControl(): HTMLElement {
      return DomUtils.getNullSafeHtmlElementById('stitches-per-hour');
   }

   private static stringRepresentsInteger(value: string): boolean {
      const regexp = /^\d+$/;

      return regexp.test(value);
   }

   private static stringRepresentsFloatNumber(value: string): boolean {
      const regexp = /^(\d*[.])?\d+$/;
      return regexp.test(value);
   }

   private static _getHoursADayControl(): HTMLElement {
      return DomUtils.getNullSafeHtmlElementById('hours-a-day');
   }

   private static _getDaysAWeekControl(): HTMLElement {
      return DomUtils.getNullSafeHtmlElementById('days-a-week');
   }

   private static initializePopup(htmlElementId: string, modalReference: bootstrap.Modal | null): bootstrap.Modal | null {

      const element = DomUtils.getHtmlElementById(htmlElementId);

      if ((modalReference === null) && (element !== null)) {
         return new bootstrap.Modal(element, {keyboard: false});
      }

      return modalReference;
   }

   getLogInSuccessRouteWithSuccessRedirectUrlDynamically(): string {
      if (this.selectedTemplate === null) {
         return '';
      }

      const templateId = this.selectedTemplate.getTemplateId();

      return PatternDetailComponent.getLogInSuccessRouteWithSuccessRedirectUrl(templateId);
   }

   ngOnInit(): void {
      this.pageStatus = this.pageStatuses.loading;

      const patternId = this.activatedRoute.snapshot.paramMap.get('pattern-id');
      const templateId = this.activatedRoute.snapshot.paramMap.get('template-id');

      if ((patternId === null)) {
         this.redirectHome();
         return;
      }

      this.searchPatternService.getPatternById(parseInt(patternId, 10)).subscribe((result: ProjectFullInfo) => {
         this.pattern = result;
         this.title.setTitle(result.getTitleForLanguage(this.languageDetectorService.getLanguage()));


         let selectedTemplate: ProjectTemplate | null;

         if ((templateId === null)) {
            selectedTemplate = result.getFirstTemplate();
         } else {
            selectedTemplate = result.getTemplateById(parseInt(templateId, 10));
         }

         if (selectedTemplate === null) {
            this.redirectHome();
            return;
         }

         // We need to retrieve the canonical urls from the backend because they are used in the backend too, and we don't
         // want to hardcode strings in two different places, now do we,
         this.canonical.setCanonicalAttributeForUrl(selectedTemplate.getCanonicalUrl());
         this.canonical.removeAllAlternateLinks();
         this.canonical.setAlternateAttributeForUrlAndLanguage(selectedTemplate.getCanonicalAlternateEnglish(), new English());
         this.canonical.setAlternateAttributeForUrlAndLanguage(selectedTemplate.getCanonicalAlternateSpanish(), new Spanish());
         this.canonical.setAlternateAttributeForUrlAndLanguage(selectedTemplate.getCanonicalAlternateFrench(), new French());

         this.selectedTemplate = selectedTemplate;

         this.publicProjectComments = null;

         const commentsRequest = this.searchPatternService.getProjectPublicComments(this.selectedTemplate.getProjectId());

         commentsRequest.subscribe((a: PublicProjectComment[]) => {
            this.publicProjectComments = a;
         });

         this.externalClick.externalClickEventDetected().subscribe((d: ExternalClickDetails) => {
            this.searchPatternService.logTemplateVisit(this.selectedTemplate.getTemplateId(), d);
         });

         this.safeImage = this.domSanitizer.bypassSecurityTrustUrl(this.selectedTemplate.getFirstImageUrl());
         const shoppingCart = this.shoppingCartStorageService.readFromStorage();

         const shoppingCartElement = shoppingCart.getItemByIds(this.pattern.getPatternId(), this.selectedTemplate.getTemplateId());

         if (shoppingCartElement !== null) {
            this.notes = shoppingCartElement.getNotes();
            this.inShoppingCart = !shoppingCartElement.isDeleted();
         } else {
            this.inShoppingCart = false;
            this.notes = '';
         }

         CommonNavBarRightComponent.updateShoppingCartCount(shoppingCart.countActiveItems());

         this.pageStatus = this.pageStatuses.ok;
      }, (error) => {
         this.apiClientUtils.logCodeSearchableFrontEndGenericError(
            'u6TZBRRy1HuR1nCh',
            JSON.stringify(error, null, 2)
         ).then(() => {
         });
      });

      this.userService.getUserAccountDetails().subscribe((u: User) => {
         this.userIsAdmin = u.checkIsAdmin();
         this.userIsLoggedIn = true;
      }, () => {
         this.userIsLoggedIn = false;
      });

      this.favouritesService.getCurrentUserFavouritesList().subscribe((a: PatternFavouritesContainer) => {
         this.isFavouriteProject = a.has(parseInt(patternId, 10));
      });
   }

   private redirectHome(): void {
      this.router.navigate([environment.frontEndRoutes.home]).then(() => {
      });
   }

   ngAfterViewChecked(): void {

      if ((this.selectedTemplate === null) || (this.selectedTemplate === undefined)) {
         return;
      }

      const carouselButtons = document.getElementsByClassName('xs-carousel-button');

      const arr = Array.prototype.slice.call(carouselButtons);

      arr.forEach((carouselButton, key: number) => {
         carouselButton.setAttribute('data-bs-slide-to', key.toString());
      });

      const myCarousel = DomUtils.getHtmlElementById('pattern-carousel');
      const swatchSelector = DomUtils.getHtmlElementById('swatch-selector-id');
      const pageLoadedSuccessfully = this.pageStatus === this.pageStatuses.ok;
      const multipleImages = this.selectedTemplate.getImgUrls().length > 1;

      const mainCondition =
         pageLoadedSuccessfully &&
         multipleImages;

      if (mainCondition && !this.swatchSelectorEventAdded && (myCarousel !== null) && (swatchSelector !== null)) {
         // Carousel accessible
         this.swatchSelectorEventAdded = true;
         myCarousel.addEventListener('slide.bs.carousel', (event: Event) => {
            if (event['to'] === 0) {
               swatchSelector.style.display = 'block';
            } else {
               swatchSelector.style.display = 'none';
            }
         });
      }

      this.patternDeadlineCalculatorModal = PatternDetailComponent.initializePopup(this.patternDeadlineCalculatorFormId, this.patternDeadlineCalculatorModal);
      this.patternDeadlineCalculatorResult = PatternDetailComponent.initializePopup(this.patternDeadlineCalculationFormModalId, this.patternDeadlineCalculatorResult);
      this.patternDeadlineCalculatorError = PatternDetailComponent.initializePopup(this.patternDeadlineCalculatorErrorModalId, this.patternDeadlineCalculatorError);
      this.leaveACommentModal = PatternDetailComponent.initializePopup(this.leaveACommentFormModalId, this.leaveACommentModal);
   }

   validateStitchesPerHourControl(): boolean {
      const stitchesPerHourControl = PatternDetailComponent._getStitchesPerHourControl() as HTMLFormElement;
      const value = stitchesPerHourControl.value;

      if (!PatternDetailComponent.stringRepresentsInteger(value)) {
         PatternDetailComponent._setFormComponentInvalid(stitchesPerHourControl);
         return false;
      }

      if (parseInt(value, 10) <= 0) {
         PatternDetailComponent._setFormComponentInvalid(stitchesPerHourControl);
         return false;
      }

      PatternDetailComponent._setFormComponentValid(stitchesPerHourControl);
      return true;
   }

   resetValidateStitchesPerHourControl(): void {
      const stitchesPerHourControl = PatternDetailComponent._getStitchesPerHourControl() as HTMLFormElement;
      PatternDetailComponent._resetFormComponentValidation(stitchesPerHourControl);
      // PatternDetailComponent._showHideFinishDateControl(false);
   }

   validateHoursADayControl(): boolean {
      const hoursADayControl = PatternDetailComponent._getHoursADayControl() as HTMLFormElement;

      const value = hoursADayControl.value;

      if (!PatternDetailComponent.stringRepresentsFloatNumber(value)) {
         PatternDetailComponent._setFormComponentInvalid(hoursADayControl);
         return false;
      }

      const floatValue = parseFloat(value);

      if (!((floatValue > 0) && !(floatValue > 24))) {
         PatternDetailComponent._setFormComponentInvalid(hoursADayControl);
         return false;
      }

      PatternDetailComponent._setFormComponentValid(hoursADayControl);
      return true;
   }

   resetHoursADayControl(): void {
      const control = PatternDetailComponent._getHoursADayControl();
      PatternDetailComponent._resetFormComponentValidation(control);
   }

   validateDaysAWeekControl(): boolean {
      const control = PatternDetailComponent._getDaysAWeekControl() as HTMLFormElement;
      const val = control.value;

      if (!PatternDetailComponent.stringRepresentsInteger(val)) {
         PatternDetailComponent._setFormComponentInvalid(control);
         return false;
      }

      const parsedNumber = parseInt(val, 10);

      if (!((parsedNumber >= 1) && (parsedNumber <= 7))) {
         PatternDetailComponent._setFormComponentInvalid(control);
         return false;
      }

      PatternDetailComponent._setFormComponentValid(control);
      return true;
   }

   resetDaysAWeekControl(): void {
      const control = PatternDetailComponent._getDaysAWeekControl() as HTMLFormElement;
      PatternDetailComponent._resetFormComponentValidation(control);
   }

   calculateDeadline(): void {
      const stitchesPerHourValid = this.validateStitchesPerHourControl();
      const hoursPerDayValid = this.validateHoursADayControl();
      const daysAWeekValid = this.validateDaysAWeekControl();

      if (stitchesPerHourValid && hoursPerDayValid && daysAWeekValid && (this.selectedTemplate !== null)) {
         const stitchesPerHourControl = PatternDetailComponent._getStitchesPerHourControl() as HTMLFormElement;
         const hoursPerDayControl = PatternDetailComponent._getHoursADayControl() as HTMLFormElement;
         const daysAWeekControl = PatternDetailComponent._getDaysAWeekControl() as HTMLFormElement;

         this.stitchesPerHour = parseInt(stitchesPerHourControl.value, 10);
         this.hoursADay = parseFloat(hoursPerDayControl.value);
         this.daysAWeek = parseInt(daysAWeekControl.value, 10);
         this.stitchCount = this.selectedTemplate.getTotalStitches();

         this.calculatorService.calculateWorkload(
            this.selectedTemplate.getTemplateId(),
            this.stitchCount,
            this.stitchesPerHour,
            this.hoursADay,
            this.daysAWeek
         ).subscribe((deadlineCalculatorResponse: DeadlineCalculatorResponseApi) => {
            this.patternDeadline = deadlineCalculatorResponse.deadline.formatted.longDate.toString();

            if (this.patternDeadlineCalculatorModal !== null) {
               this.patternDeadlineCalculatorModal.hide();
            }
            if (this.patternDeadlineCalculatorResult !== null) {
               this.patternDeadlineCalculatorResult.show();
            }
         }, () => {
            if (this.patternDeadlineCalculatorModal !== null) {
               this.patternDeadlineCalculatorModal.hide();
            }
            if (this.patternDeadlineCalculatorError !== null) {
               this.patternDeadlineCalculatorError.show();
            }
         });
      }
   }

   showCalculatorModal(): void {
      if (this.patternDeadlineCalculatorModal !== null) {
         this.patternDeadlineCalculatorModal.show();
      }
   }

   updateNotes(): void {
      if (this.selectedTemplate === null) {
         return;
      }

      const additionalNotesElement = document.getElementById('additional-purchase-notes') as HTMLFormElement;

      const shoppingCart = this.shoppingCartStorageService.readFromStorage();


      const patternId = this.pattern.getPatternId();
      const templateId = this.selectedTemplate.getTemplateId();

      let shoppingCartItem = shoppingCart.getItemByIds(patternId, templateId);
      const notes = additionalNotesElement.value;
      if (shoppingCartItem === null) {
         shoppingCartItem = new ShoppingCartItem(patternId, templateId, 1, notes, false);
         shoppingCart.addItem(shoppingCartItem);
      } else {
         shoppingCartItem.setNotes(notes);
      }

      this.shoppingCartStorageService.writeToStorage(shoppingCart);
   }

   sanitizeImage(imageUrl: string): SafeUrl {
      return this.domSanitizer.bypassSecurityTrustUrl(imageUrl);
   }

   changeSelectedTemplate(changedTo: number): void {
      const route = `/pattern/${this.pattern.getPatternId()}/template/${changedTo}`;

      // Hack to force a page refresh. Since we are still in the same route (although with different parameters), the URL
      // will change in the browser, but the contents won't update. This is why we navigate away from the current page to the
      // home page (/), and we do it silently (skipLocationChange: true), and when that action completes (then), then
      // navigate back to the initial page with the new parameters.
      // As seen in: https://stackoverflow.com/questions/48006629/angular-navigate-to-the-same-route-with-different-parameter

      this.router.navigate(['/'], {skipLocationChange: true}).then(() => {
         this.router.navigate([route]).then(() => {
         });
      });
   }

   searchBySelectedTag(tagId: number) {
      const searchCriteria = new SearchRequest([], [], []);
      const route = environment.frontEndRoutes.searchResults + '?' + searchCriteria.serializeAsQueryString();
      this.router.navigateByUrl(route).then(() => {
         searchCriteria.includeTagId(tagId);
         CommonSearchBarComponent.searchRequestParametersSubject.next(searchCriteria);
      });
   }

   addFreePatternToCollection(): void {
      this.addFreePatternToCollectionButton.setBusy(true);

      if (this.selectedTemplate === null) {
         this.addFreePatternToCollectionButton.setBusy(false);
         this.logInFirstPopup.show();
      } else {

         const templateId = this.selectedTemplate.getTemplateId();

         this.userService.getCurrentUser().subscribe(() => {
            this.router.navigateByUrl(PatternDetailComponent.getAddTemplateUrl(templateId)).then();
         }, (error: StandardApiErrorResponse) => {
            const code = error.getCode();
            const err1 = environment.xstitch_api_error_codes.users.userNotFound;
            const err2 = environment.xstitch_api_error_codes.users.userNotLoggedIn;
            if ((code === err1) || (code === err2)) {
               this.userService.unauthorizedFreeTemplateDownload(templateId).subscribe(() => {
               }, (e: StandardApiErrorResponse) => {
                  const unmanaged = 'Code: \'' + e.getCode() + '\'. Message: \'' + e.getMessage() + '\'';
                  this.apiClientUtils.logCodeSearchableFrontEndGenericError('rGVsGyZQ4wT28vE7', unmanaged).then();
               });
               this.addFreePatternToCollectionButton.setBusy(false);
               this.logInFirstPopup.show();
            }
         });
      }
   }

   logInAndLeaveComment(): void {
      if (this.selectedTemplate !== null) {
         const patternId = this.selectedTemplate.getProjectId();
         const templateId = this.selectedTemplate.getTemplateId();
         const paramName = environment.queryStringParameterNames.successRedirect;
         const loginRoute = environment.frontEndRoutes.loginRoute;
         const url = loginRoute + '?' + paramName + '=pattern/' + patternId.toString() + '/template/' + templateId.toString();
         this.router.navigateByUrl(url).then(() => {
         });
      }
   }

   leaveAComment(): void {
      this.leaveACommentModal?.show();
   }

   redirectToCollection(): void {
      this.router.navigateByUrl(environment.frontEndRoutes.accountHome).then(() => {
      });
   }

   postComment(): void {
      const text = this.plainTextMaxLengthComponent.getWellFormedText();
      const anonymousControl = DomUtils.getNullSafeHtmlFormElementById('comment-will-be-anonymous');
      const publicControl = DomUtils.getNullSafeHtmlFormElementById('comment-will-be-public');

      const isAnonymous = anonymousControl.checked;
      const isPublic = publicControl.checked;

      if (this.commentValidated && (this.selectedTemplate !== null)) {
         this.sendCommentButton.busy = true;
         const subs = this.userService.leaveCommentForProject(
            this.selectedTemplate.getProjectId(),
            text,
            !isAnonymous,
            isPublic
         );

         subs.subscribe(() => {
            this.leaveACommentModal?.hide();
            this.sendCommentButton.busy = false;
            this.commentSent = true;
            this.commentValidated = false;
            this.plainTextMaxLengthComponent.resetTextAreaContentValidation();
            const thanksElement = DomUtils.getNullSafeHtmlElementById(this.commentSentSuccessModalId);
            const thanksModal = new bootstrap.Modal(thanksElement);
            thanksModal.show();
         });
      }
   }

   getCarouselImages(): Array<string> {
      const result = new Array<string>();

      if (this.selectedTemplate === null) {
         return result;
      }

      const templateImgs = this.selectedTemplate.getImgUrls();

      if (this.pattern === null) {
         return result;
      }

      result.push(this.pattern.getProjectShowboxImageUrl());

      templateImgs.forEach((s: string, index: number) => {
         // By convention, the first image of the templates is supposed to be the image used to generate the pattern (the
         // template showbox image). We skip that and use the pattern showbox image.
         //
         // Reason being, the template showbox image can be too small for the view, and we already have a realistic preview
         // anyway, which is the one with the grid
         if (index !== 0) {
            result.push(s);
         }
      });

      const lan = this.languageDetectorService.getLanguage();

      if (lan.isFrench()) {
         result.push('assets/pdf_combinations/french.png');
      } else if (lan.isSpanish()) {
         result.push('assets/pdf_combinations/spanish.png');
      } else {
         result.push('assets/pdf_combinations/english.png');
      }

      return result;
   }
}
