import {VerificationDetailsInterface} from '../interface/verification-details-interface';
import {AbstractPalette} from '../../../api-client/search-pattern/type/palette/abstract-palette';
import {PaletteFactory} from '../../../api-client/search-pattern/type/palette/palette-factory';
import {AbstractLanguage} from '../../../locale/language-detector/type/abstract-language';
import {LanguageFactory} from '../../../locale/language-detector/helper/language-factory';

export class VerificationDetails {
   public constructor(
      private projectShowcase: string,
      private originalListingUrl: string,
      private title: string,
      private stitchesWidth: number,
      private stitchesHeight: number,
      private coveragePercentage: number,
      private totalStitches: number,
      private isFree: boolean,
      private palettes: AbstractPalette[],
      private pdfFormat: number,
      private downloadToken: string,
      private language: AbstractLanguage
   ) {
   }

   public static constructFromInterface(i: VerificationDetailsInterface) {

      const lan = LanguageFactory.createFromDbId(i.language);

      return new VerificationDetails(
         i.projectShowcase,
         i.originalListingUrl,
         i.title,
         i.stitchesWidth,
         i.stitchesHeight,
         i.coveragePercentage,
         i.totalStitches,
         i.isFree,
         PaletteFactory.collectionFromInterfaces(i.palettes),
         i.pdfFormat,
         i.downloadToken,
         lan
      );
   }

   public getLanguage(): AbstractLanguage {
      return this.language;
   }

   public getDownloadToken(): string {
      return this.downloadToken;
   }

   public getPdfFormat(): number {
      return this.pdfFormat;
   }

   public getPalettes(): AbstractPalette[] {
      return this.palettes;
   }

   public getIsFree(): boolean {
      return this.isFree;
   }

   public getTotalStitches(): number {
      return this.totalStitches;
   }

   public getCoveragePercentage(): number {
      return this.coveragePercentage;
   }

   public getStitchesHeight(): number {
      return this.stitchesHeight;
   }

   public getStitchesWidth(): number {
      return this.stitchesWidth;
   }

   public getProjectShowcase(): string {
      return this.projectShowcase;
   }

   public getOriginalListingUrl(): string {
      return this.originalListingUrl;
   }

   public getTitle(): string {
      return this.title;
   }

   getReferenceForContact(): string {
      return this.getDownloadToken() + '/' + this.getPdfFormat() + '/' + this.getLanguage().getDbId();
   }
}
